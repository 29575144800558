import type { ServerSideNumberFilterModel } from '../FilterRenderers/ServerSideNumberFilterRenderer'

export function isValidFilter(filter: ServerSideNumberFilterModel): boolean {
  if (!filter.type) return false

  const validTypes = ['any_value', 'no_value']
  if (validTypes.includes(filter.type)) return true

  return filter.type === 'inRange'
    ? typeof filter.value === 'number' && typeof filter.valueTo === 'number'
    : typeof filter.value === 'number'
}
