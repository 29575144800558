import { isBefore, isDate } from 'date-fns'
import type {
  DateFilterModel,
  Position,
} from './FilterRenderers/DateFilterRenderer'

export function getOrderedDates(dateValue: DateFilterModel) {
  const { start, end } = dateValue
  if (isDate(start) && isDate(end)) {
    return isBefore(start!, end!)
      ? dateValue
      : { ...dateValue, end: start, start: end }
  }

  return dateValue
}

export function isValidDateSelection(value: DateFilterModel) {
  /** return true if:
   *  - range selection
   *    - both are empty
   *    - both start and end have valid dates
   *  - return false otherwise for range selection
   *  any single date select always calls onchange
   */

  const { start, end } = value

  if (value.type === 'range') {
    // cleared by clicking both x's on DateInputs
    if (!start && !end) {
      return true
    }

    if (isDate(start) && isDate(end)) {
      return true
    }

    // one is null/unfilled other is a Date don't call onChange
    return false
  }
  // always call onChange for single date
  return true
}

export function getValueFromSelection(
  existingValue: DateFilterModel,
  date: Date | null,
  position: Position
): DateFilterModel {
  const otherDateInValue =
    position === 'start' ? existingValue.end : existingValue.start

  // single date filter puts date value in both indices
  if (existingValue.type === 'single') {
    return {
      type: 'single',
      start: isDate(date) ? date : null,
    }
  }
  if (!isDate(date) && !isDate(otherDateInValue)) {
    // range, check if both values are now clear
    return {
      type: 'range',
    }
  }
  if (position === 'end') {
    // partial or complete range. reorder dates if complete & end > start
    return getOrderedDates({
      type: 'range',
      start: otherDateInValue,
      end: date,
    })
  }
  return getOrderedDates({ type: 'range', start: date, end: otherDateInValue })
}
