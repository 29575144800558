const log: typeof console.log = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args)
  }
}

const warn: typeof console.warn = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(...args)
  }
}

const error: typeof console.error = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(...args)
  }
}

export const logger = {
  log,
  warn,
  error,
}
