import debounce from 'lodash.debounce'
import React from 'react'
import type { UNSAFE_TypeaheadChangeEvent } from '../Typeahead'
import { EmptyResultsControlTooltip } from './EmptyResultsControlTooltip'
import { StyledSearch } from './Filters.styles'

type SearchProps = {
  onSearch?: React.ChangeEventHandler<HTMLInputElement>
  onDebouncedSearch?: (value: string) => void
  isDisabled: boolean
  placeholder?: string
  isServerSide?: boolean
  ['data-qa']?: string
}

export const Search = ({
  onSearch,
  onDebouncedSearch,
  isDisabled,
  placeholder,
  ['data-qa']: dataQa,
}: SearchProps) => {
  const [internalValue, setInternalValue] = React.useState('')

  const onChange = React.useCallback(
    (value: string, event: UNSAFE_TypeaheadChangeEvent) => {
      setInternalValue(value)
      if (onSearch) {
        onSearch(event as React.ChangeEvent<HTMLInputElement>)
      }
      if (onDebouncedSearch) {
        const debouncedSetSearchValue = debounce((value: string) => {
          onDebouncedSearch(value)
        }, 250)
        debouncedSetSearchValue(value)
      }
    },
    [onSearch, onDebouncedSearch]
  )

  return (
    <EmptyResultsControlTooltip hasSearch enabled={isDisabled}>
      <StyledSearch
        $isEmpty={!internalValue.length}
        disabled={isDisabled}
        value={internalValue}
        onChange={onChange}
        placeholder={placeholder ?? 'Search'}
        data-qa={dataQa}
      />
    </EmptyResultsControlTooltip>
  )
}
