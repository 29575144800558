import React from 'react'
import type { Filter, FiltersPanelProps } from './Filter.types'
import { StyledFiltersList } from './Filters.styles'

export interface FilterListItemProps<T> extends FiltersPanelProps<T> {
  filtersInGroup: Filter[]
}

export function FilterListItems<T>(props: FilterListItemProps<T>) {
  const {
    filtersInGroup,
    FilterListItem,
    onFilterChange,
    getColumnDefinition,
  } = props
  return (
    <StyledFiltersList
      data-qa={`${
        props.dataQaPrefix
          ? `${props.dataQaPrefix}-possible-filters-list`
          : 'possible-filters-list'
      }`}
    >
      {filtersInGroup.map((filter: Filter) => {
        const columnDefinition = getColumnDefinition?.(filter.field)
        const FilterRenderer =
          columnDefinition?.filterRenderer ?? filter.filterRenderer
        const field = columnDefinition?.field ?? filter.field

        if (!FilterRenderer) {
          return null
        }

        return (
          <FilterListItem
            key={filter.field}
            columnDefinition={columnDefinition}
            Component={FilterRenderer}
            onChange={(updatedValues: any[]) =>
              onFilterChange(field, updatedValues)
            }
            options={filter?.options}
            value={filter?.filterValues}
            filterName={filter?.name}
            dataQaPrefix={props.dataQaPrefix}
            {...(!columnDefinition ? filter.filterProps : {})}
          />
        )
      })}
    </StyledFiltersList>
  )
}
