import debounce from 'lodash.debounce'
import React from 'react'
import { FlexList } from '../../FlexList'
import { Input } from '../../Input'
import { Select } from '../../Select'
import { Typography } from '../../Typography'
import { useI18nContext } from '../../_hooks/I18n'

type AcceptedTypes =
  | 'any_value'
  | 'no_value'
  | 'inRange'
  | 'greaterThan'
  | 'greaterThanOrEqual'
  | 'lessThan'
  | 'lessThanOrEqual'

export interface NumberFilterModel {
  type?: AcceptedTypes
  filterType?: 'number'
  filter?: number | null
  filterTo?: number | null
}

export type NumberFilterSelection = {
  id: AcceptedTypes
  label: string
}

export type NumberFilterValue =
  | {
      type?: AcceptedTypes
      filter?: number
      filterTo?: number
    }
  | undefined

export interface IClientSideFilter {
  active: boolean
  field: string
  name: string
  readonly type: 'list' | 'number'
}

export interface NumberFilter extends IClientSideFilter {
  readonly type: 'number'
  value?: NumberFilterValue
}

const scope = 'core.filters.numberFilter'

function useFilterOptions() {
  const I18n = useI18nContext()

  const filterOptions: NumberFilterSelection[] = React.useMemo<
    NumberFilterSelection[]
  >(() => {
    return [
      {
        id: 'any_value',
        label: I18n.t('options.any_value', {
          defaultValue: 'Any Value',
          scope,
        }),
      },
      {
        id: 'inRange',
        label: I18n.t('options.is_between', {
          defaultValue: 'Is Between',
          scope,
        }),
      },
      {
        id: 'greaterThan',
        label: I18n.t('options.greater_than', {
          defaultValue: 'Is Greater Than',
          scope,
        }),
      },
      {
        id: 'greaterThanOrEqual',
        label: I18n.t('options.greater_than_equal_to', {
          defaultValue: 'Is Greater Than or Equal To',
          scope,
        }),
      },
      {
        id: 'lessThan',
        label: I18n.t('options.less_than', {
          defaultValue: 'Is Less Than',
          scope,
        }),
      },
      {
        id: 'lessThanOrEqual',
        label: I18n.t('options.less_than_equal_to', {
          defaultValue: 'Is Less Than or Equal To',
          scope,
        }),
      },
      {
        id: 'no_value',
        label: I18n.t('options.no_value', { defaultValue: 'No Value', scope }),
      },
    ]
  }, [I18n])

  return filterOptions
}

export interface NumberFilterProps {
  onChange: (value: unknown[] | NumberFilterModel | undefined) => void
  value: NumberFilterValue
  'aria-labelledby'?: string
}

export function isNumberFilterModel(
  value?: string[] | NumberFilterModel
): value is NumberFilterModel {
  return (value as NumberFilterModel)?.filterType === 'number'
}
function toNumberFilterModel(
  value: NumberFilterValue
): NumberFilterModel | undefined {
  if (value === undefined || value === null) {
    return undefined
  }

  if (value.type === 'any_value') {
    return {
      type: 'any_value',
      filterType: 'number',
    }
  }

  if (value.type === 'no_value') {
    return {
      type: 'no_value',
      filterType: 'number',
    }
  }

  if (value.type === 'inRange') {
    return {
      type: 'inRange',
      filter: value.filter,
      filterTo: value.filterTo || 1e6,
      filterType: 'number',
    }
  }

  return {
    filter: value.filter,
    filterTo: value.filterTo,
    filterType: 'number',
    type: value.type,
  }
}

function toNumberFilterValue(
  value?: any[] | NumberFilterModel
): NumberFilterValue | undefined {
  if (isNumberFilterModel(value)) {
    return {
      filter: value.filter ?? undefined,
      filterTo: value.filterTo ?? undefined,
      type: value.type,
    }
  }

  return undefined
}

function isValidInput(
  value: NumberFilterModel | undefined
): boolean | undefined {
  if (value === undefined) {
    return
  }

  if (value.type === undefined || value.type === null) {
    return
  }

  if (value.type === 'any_value' || value.type === 'no_value') {
    return true
  }

  if (value.filter === undefined) {
    return
  }

  return true
}

function getOnSelectChangeValue(
  prev: NumberFilterValue,
  next: AcceptedTypes
): NumberFilterValue {
  if (next === 'any_value' || next === 'no_value') {
    return {
      type: next,
    }
  }

  return {
    ...prev,
    type: next,
  }
}

function getLowerLimitChangeValue(
  prev: NumberFilterValue,
  input: any
): NumberFilterValue {
  if (prev?.type === 'any_value' || prev?.type === 'no_value') {
    return {
      type: prev.type,
    }
  }

  if (prev?.type === 'inRange') {
    return {
      type: prev.type,
      filter: input,
      filterTo: prev.filterTo ?? 1e6,
    }
  }

  return {
    type: prev?.type,
    filter: input,
  }
}

export const NumberFilterRenderer = React.memo(
  function (props: NumberFilterProps) {
    const I18n = useI18nContext()
    const defaultFilterOptions = useFilterOptions()
    const ariaLabelledBy = props['aria-labelledby']
    const lowerLimitInputRef = React.useRef<HTMLInputElement | null>(null)
    const [localValue, setLocalValue] = React.useState<NumberFilterValue>(
      toNumberFilterValue(props.value)
    )
    const [externalValue, setExternalValue] = React.useState<NumberFilterValue>(
      toNumberFilterValue(props.value)
    )
    const localLabel = React.useMemo(() => {
      if (localValue === undefined || localValue === null) {
        return undefined
      }

      return defaultFilterOptions.find(
        (option) => option.id === localValue.type
      )?.label
    }, [localValue])

    const hasNumberInputsEnabled = React.useMemo(() => {
      return (
        localValue &&
        localValue.type !== 'any_value' &&
        localValue.type !== 'no_value'
      )
    }, [localValue])

    const hasUpperLimitValue = React.useMemo(() => {
      return localValue?.type === 'inRange'
    }, [localValue])

    const onClear = React.useCallback(() => {
      setLocalValue(undefined)
      props.onChange(undefined)
    }, [])

    const onSelect: React.ComponentProps<typeof Select>['onSelect'] =
      React.useCallback(({ item }: { item: NumberFilterSelection }) => {
        setLocalValue((prev) => {
          return getOnSelectChangeValue(prev, item.id)
        })

        setExternalValue((prev) => {
          return getOnSelectChangeValue(prev, item.id)
        })

        queueMicrotask(() => lowerLimitInputRef.current?.focus())
      }, [])

    const onUpdateLowerLimit: React.ComponentProps<typeof Input>['onChange'] =
      React.useCallback((event) => {
        const inputValue = event.target.value

        setLocalValue((prev) => {
          return getLowerLimitChangeValue(prev, inputValue)
        })

        if (inputValue === undefined || inputValue === '') {
          return
        }

        setExternalValue((prev) => {
          return getLowerLimitChangeValue(prev, inputValue)
        })
      }, [])

    const onUpdateUpperLimit: React.ComponentProps<typeof Input>['onChange'] =
      React.useCallback((event) => {
        const inputValue = event.target.value
        setLocalValue((prev) => {
          return {
            type: prev?.type,
            filter: prev?.filter,
            filterTo: inputValue,
          }
        })

        if (inputValue === undefined || inputValue === '') {
          return
        }

        setExternalValue((prev) => {
          return {
            type: prev?.type,
            filter: prev?.filter,
            filterTo: inputValue,
          }
        })
      }, [])

    const valueUpdate = React.useCallback(debounce(props.onChange, 500), [])

    React.useEffect(() => {
      setLocalValue(toNumberFilterValue(props.value))
    }, [props.value])

    React.useEffect(() => {
      const value = toNumberFilterModel({
        type: externalValue?.type ?? localValue?.type,
        filter: externalValue?.filter ?? localValue?.filter,
        filterTo: externalValue?.filterTo ?? localValue?.filterTo,
      })
      isValidInput(value) && valueUpdate(value)
    }, [externalValue])

    return (
      <>
        <Select
          block
          label={localLabel}
          onClear={onClear}
          onSelect={onSelect}
          aria-labelledby={ariaLabelledBy}
          placeholder={I18n.t('labels.placeholder', {
            defaultValue: 'Select an item',
            scope,
          })}
        >
          {defaultFilterOptions.map((item) => (
            <Select.Option
              key={item.id}
              value={item}
              selected={item.id === localValue?.type}
            >
              {item.label}
            </Select.Option>
          ))}
        </Select>
        {hasNumberInputsEnabled && (
          <FlexList
            alignItems="center"
            style={{ width: '100%' }}
            grow="1"
            flex="1"
            padding="md none"
            size="xs"
          >
            <Input
              ref={lowerLimitInputRef}
              type="number"
              placeholder={I18n.t('labels.input_placeholder', {
                defaultValue: 'Enter Value',
                scope,
              })}
              value={localValue?.filter}
              onChange={onUpdateLowerLimit}
            />

            {hasUpperLimitValue && (
              <Typography>
                {I18n.t('labels.and', {
                  defaultValue: 'and',
                  scope,
                })}
              </Typography>
            )}

            {hasUpperLimitValue && (
              <Input
                type="number"
                value={localValue?.filterTo}
                onChange={onUpdateUpperLimit}
              />
            )}
          </FlexList>
        )}
      </>
    )
  },
  (prev, next) => {
    return JSON.stringify(prev.value) === JSON.stringify(next.value)
  }
)
