import type { AcceptedTypes } from '../FilterRenderers/ServerSideNumberFilterRenderer'

export function getNewFilterType(newType: AcceptedTypes): {
  type: AcceptedTypes
  label: string
} {
  if (newType === 'no_value') {
    return {
      type: newType,
      label: 'No Value',
    }
  }

  return {
    type: newType !== 'inRange' ? newType : 'inRange',
    label: '',
  }
}
