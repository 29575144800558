import React from 'react'
import { Box } from '../Box'
import { Flex } from '../Flex'
import { FlexList } from '../FlexList'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type { Props } from '../_utils/types'
import { FiltersPanel } from './Panel'
import { PanelToggleButton } from './PanelToggleButton'
import { QuickFilters } from './QuickFilters'
import { Search } from './Search'

/**
 * - Filters
 *   - Filters.Aside
 *     - Filters.Panel
 *   - Filters.Main
 *     - Filters.HeaderOuter
 *       - Filters.HeaderInner
 *         - Filters.Search
 *         - Filters.PanelToggleButton
 *       - Filters.QuickFilters
 *     - Table or some other content
 */

const Filters_ = (props: Props) => (
  <Flex
    style={{ height: '100%', width: '100%' }}
    alignItems="stretch"
    {...props}
  />
)

const Main = (props: Props) => (
  <Box
    flex="1"
    display="flex-column"
    alignItems="stretch"
    padding="none lg"
    {...props}
  />
)

const Aside = (props: Props) => (
  <Flex flex="0 1 0px" alignItems="stretch" {...props} />
)

const HeaderOuter = (props: Props) => <Box padding="xs none" {...props} />

const HeaderInner = (props: Props) => (
  <Box padding="xs none" display="flex-row" justifyContent="space-between">
    <FlexList size="xs" alignItems="center" marginRight="sm" {...props} />
  </Box>
)

export const Filters = addSubcomponents(
  {
    Main,
    Aside,
    HeaderOuter,
    HeaderInner,
    Search,
    PanelToggleButton,
    QuickFilters,
    Panel: FiltersPanel,
  },
  Filters_
)
