import { prop, startsWith, uniqBy } from 'ramda'
import React, { useEffect } from 'react'
import { Card } from '../../Card'
import { Flex } from '../../Flex'
import type { UNSAFE_MenuRef, UNSAFE_MenuSelection } from '../../MenuImperative'
import {
  MenuImperative,
  useMenuImperativeControlNavigation,
} from '../../MenuImperative/MenuImperative'
import { OverlayTrigger } from '../../OverlayTrigger'
import { Select } from '../../Select'
import { Spinner } from '../../Spinner'
import { useI18nContext } from '../../_hooks/I18n'
import { defaultGetId } from '../utils/defaultGetId'
import { defaultGetLabel } from '../utils/defaultGetLabel'
import {
  StyledControlsContainer,
  StyledLocationFilterMenu,
} from './LocationFilterRenderer.styles'
import type {
  LocationFilteRendererProps,
  LocationFilterOverlayProps,
  LocationOption,
  SelectionType,
} from './LocationFilterRenderer.type'

const LocationFilterOverlay = React.forwardRef<
  HTMLDivElement,
  LocationFilterOverlayProps
>(
  (
    {
      getId,
      getLabel,
      loading,
      onSelect: _onSelect,
      onSelectAll,
      onSelectSublocations,
      enableSublocations,
      selectState,
      options,
      disabledOptions,
      onSearch,
      value,
    },
    ref
  ) => {
    const [items, setItems] = React.useState<LocationOption[]>(options)
    const [searching, setSearching] = React.useState<boolean>(false)
    const menuRef = React.useRef<UNSAFE_MenuRef | null>(null)
    const I18n = useI18nContext()
    const { menuProps, menuNavigationTriggerProps } =
      useMenuImperativeControlNavigation(menuRef)

    const selectAllOption = {
      label: I18n.t('core.filters.locationFilter.selectAll'),
      id: 'select_all',
    }

    const includeSublocationOption = {
      label: I18n.t('core.filters.locationFilter.includeSublocations'),
      id: 'include_sublocations',
    }
    const onSelect = (selection: UNSAFE_MenuSelection) => {
      if (selection.item.id === selectAllOption.id) {
        return onSelectAll()
      }

      if (selection.item.id === includeSublocationOption.id) {
        _onSelect(selection)
        return onSelectSublocations()
      }

      return _onSelect(selection)
    }

    function defaultOnSearch(e: React.ChangeEvent<HTMLInputElement>) {
      if (e.target.value === '') {
        setSearching(false)
        setItems(options)
      } else {
        setSearching(true)
        setItems(
          options.filter((item) =>
            getLabel(item).toLowerCase().includes(e.target.value.toLowerCase())
          )
        )
      }
    }

    const _onSearch = onSearch ?? defaultOnSearch

    React.useEffect(() => {
      menuRef.current?.highlightSuggested()
    }, [])

    return (
      <Card ref={ref}>
        <StyledLocationFilterMenu
          {...menuProps}
          multiple
          role="listbox"
          ref={menuRef}
          onSelect={onSelect}
        >
          <MenuImperative.Search
            {...menuNavigationTriggerProps}
            onChange={_onSearch}
          />
          <MenuImperative.Options>
            {!searching && (
              <StyledControlsContainer>
                <MenuImperative.CheckboxItem
                  key={getId(includeSublocationOption)}
                  item={includeSublocationOption}
                  selected={enableSublocations}
                >
                  {getLabel(includeSublocationOption)}
                </MenuImperative.CheckboxItem>
                <MenuImperative.CheckboxItem
                  key={getId(selectAllOption)}
                  item={selectAllOption}
                  selected={selectState === 'all'}
                  indeterminate={selectState === 'partial'}
                >
                  {getLabel(selectAllOption)}
                </MenuImperative.CheckboxItem>
              </StyledControlsContainer>
            )}
            {!loading &&
              items.map((item, i) => {
                return (
                  <MenuImperative.CheckboxItem
                    key={getId(item)}
                    item={item}
                    selected={
                      value.map(({ id }) => id).includes(getId(item)) ||
                      disabledOptions.map(({ id }) => id).includes(getId(item))
                    }
                    suggested={i === 0}
                    disabled={disabledOptions
                      .map(({ id }) => id)
                      .includes(getId(item))}
                  >
                    {getLabel(item)}
                  </MenuImperative.CheckboxItem>
                )
              })}
          </MenuImperative.Options>
          {loading && (
            <MenuImperative.Footer>
              <Flex justifyContent="center">{<Spinner size="sm" />}</Flex>
            </MenuImperative.Footer>
          )}
        </StyledLocationFilterMenu>
      </Card>
    )
  }
)

export function LocationFilterRenderer(props: LocationFilteRendererProps) {
  const {
    loading,
    onChange,
    options,
    value = [],
    getOptions,
    onSearch,
    getId = defaultGetId,
    getLabel = defaultGetLabel,
  } = props

  const I18n = useI18nContext()
  const selectRef = React.useRef<HTMLDivElement>(null)

  // FOR VALUE PROPERTY
  const determineSelectedState = (selection: LocationOption[]) => {
    if (options.length === selection.length) {
      return 'all'
    }
    if (
      selection.length &&
      (options.length > selection.length || options.length < selection.length)
    ) {
      return 'partial'
    }

    if (selection.length === 0) {
      return 'none'
    }
    return 'none'
  }

  const [selectState, setSelectState] = React.useState<SelectionType>(
    determineSelectedState(value)
  )

  React.useEffect(() => {
    setSelectState(determineSelectedState(value))
  }, [value])
  // FOR VALUE PROPERTY

  // FOR OPTIONS PROPERTY
  const enableSublocationsInitialValue = value.some(
    (selected: LocationOption) => selected.sublocations?.length
  )

  const normalizeLabel = (label: string) =>
    label
      .split('>')
      .map((part) => part.trim())
      .join(' > ')

  const findSublocations = (selected: LocationOption) => {
    return options.filter((option: LocationOption) => {
      return (
        startsWith(
          normalizeLabel(getLabel(selected)).toLowerCase(),
          normalizeLabel(getLabel(option)).toLowerCase()
        ) && option.id !== selected.id
      )
    })
  }
  const ids = React.useMemo(() => value.map(({ id }) => id), [value])
  const [selectedValueIds, setSelectedValueIds] =
    React.useState<(string | number)[]>(ids)
  const getUniqDisabledValues = () => {
    const newDisabledValues: LocationOption[] = []
    value.forEach((location: LocationOption) => {
      newDisabledValues.push(...findSublocations(location))
    })
    // for disabling only options were not selected
    const uniqDisabledValues = uniqBy(prop('id'), newDisabledValues).filter(
      ({ id }) => !selectedValueIds.includes(id)
    )

    return uniqDisabledValues
  }
  const [disabledValues, setDisabledValues] = React.useState<LocationOption[]>(
    []
  )
  const [enableSublocations, setEnableSublocations] =
    React.useState<boolean>(false)
  useEffect(() => {
    // sets the initial state of the sublocation toggle after the options are loaded
    if (enableSublocationsInitialValue && options.length) {
      setDisabledValues(getUniqDisabledValues())
      setEnableSublocations(true)
    }
  }, [options])
  // FOR OPTIONS PROPERTY

  // FOR onSelect PROPERTY
  const handleSelectSublocations = (selected: LocationOption) => {
    if (enableSublocations) {
      const filteredOptions = options.filter((option: LocationOption) => {
        return (
          startsWith(
            getLabel(selected).toLowerCase().replace(/ /g, ''),
            getLabel(option).toLowerCase().replace(/ /g, '')
          ) && option.id !== selected.id
        )
      })

      return filteredOptions
    } else {
      return []
    }
  }
  const removeSelections = React.useCallback(
    function (selection: LocationOption, selected: LocationOption[]) {
      const sublocations = handleSelectSublocations(selection)
      const disabledValueIds = disabledValues.map(({ id }) => id)
      //find sublocations that were disabled
      const sublocationsToRemoveFromDisabledIds = sublocations
        .filter(({ id }) => disabledValueIds.includes(id))
        .map(({ id }) => id)
      //filter disabled locations
      setDisabledValues(
        disabledValues.filter(
          ({ id }) => !sublocationsToRemoveFromDisabledIds.includes(id)
        )
      )
      return selected.filter((item) => getId(item) !== getId(selection))
    },
    [enableSublocations, disabledValues]
  )
  const onSelect = (selection: UNSAFE_MenuSelection) => {
    if (selection.item.id === 'select_all') {
      return
    }
    if (selection.item.id === 'include_sublocations') {
      return onChange(
        value.map((item: LocationOption) => ({
          ...item,
          sublocations: enableSublocations ? [] : findSublocations(item),
        }))
      )
    }

    let newSelections = []

    if (selectedValueIds.includes(getId(selection.item))) {
      newSelections = removeSelections(selection.item, value)
    } else {
      newSelections = [...value, selection.item]
      const sublocationsToDisable = handleSelectSublocations(selection.item)

      //setting only sublocations that is not selected previously (for nested sublocations)
      const newDisabledValues = [
        ...disabledValues,
        ...sublocationsToDisable.filter(
          ({ id }) => !selectedValueIds.includes(id)
        ),
      ]
      setDisabledValues(newDisabledValues)
    }

    setSelectState(determineSelectedState(newSelections))
    setSelectedValueIds(newSelections.map(({ id }) => id))
    //add sublocation param that will be empty for enableSublocation: false and fullfilled in other case
    onChange(
      newSelections.map((item) => ({
        ...item,
        sublocations: handleSelectSublocations(item),
      }))
    )
  }
  // FOR onSelect PROPERTY

  // FOR onSelectAll PROPERTY
  const onSelectAll = React.useCallback(() => {
    if (selectState === 'none' || selectState === 'partial') {
      setSelectState('all')
      setSelectedValueIds(options.map(({ id }) => id))
      onChange(options)
    }

    if (selectState === 'all') {
      setSelectState('none')
      setSelectedValueIds([])
      onChange([])
    }
  }, [options, selectState, selectedValueIds])
  // FOR onSelectAll PROPERTY

  // FOR onSelectSublocations PROPERTY
  const onSelectSublocations = React.useCallback(() => {
    if (enableSublocations) {
      setDisabledValues([])
    } else {
      setDisabledValues(getUniqDisabledValues())
    }
    setEnableSublocations(!enableSublocations)
  }, [enableSublocations, value, selectedValueIds, options])
  // FOR onSelectSublocations PROPERTY

  const overlay = (
    <LocationFilterOverlay
      onSearch={onSearch}
      value={value}
      options={options}
      disabledOptions={disabledValues}
      enableSublocations={enableSublocations}
      getId={getId}
      getLabel={getLabel}
      loading={loading}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSelectSublocations={onSelectSublocations}
      selectState={selectState}
      ref={selectRef}
    />
  )

  return (
    <OverlayTrigger
      overlay={overlay}
      ref={selectRef}
      beforeShow={getOptions}
      placement={'bottom-left'}
    >
      <Select.Button
        block
        onClear={() => {
          setEnableSublocations(false)
          setDisabledValues([])
          onChange([])
        }}
        placeholder={I18n.t('core.filters.locationFilter.locations')}
        label={
          value.length
            ? `${I18n.t('core.filters.locationFilter.locations')} (${
                value.length
              })`
            : ''
        }
      ></Select.Button>
    </OverlayTrigger>
  )
}
