import React from 'react'
import { MultiSelect, type MultiSelectProps } from '../../MultiSelect'

export interface MultiSelectFilterRendererProps
  extends Omit<MultiSelectProps, 'beforeShow'> {
  getOptions?: () => Promise<boolean>
}

export function MultiSelectFilterRenderer(
  props: MultiSelectFilterRendererProps
) {
  const {
    options,
    value,
    onChange,
    loading,
    getOptions,
    onSearch,
    onScrollBottom,
    placeholder,
    ...rest
  } = props

  return (
    <MultiSelect
      {...(getOptions && { beforeShow: getOptions })}
      block
      loading={loading}
      getId={props.getId}
      getLabel={props.getLabel}
      {...(placeholder && { placeholder })}
      onChange={onChange}
      options={options}
      value={value}
      {...(onSearch && { onSearch })}
      {...(onScrollBottom && { onScrollBottom })}
      {...rest}
    />
  )
}
