import React from 'react'
import { Tooltip } from '../Tooltip'
import { useI18nContext } from '../_hooks/I18n'

export const EmptyResultsControlTooltip = ({
  enabled,
  children,
  featureI18nKey,
  hasSearch,
}: {
  featureI18nKey?: string
  enabled: boolean
  hasSearch?: boolean
  children: React.ReactNode
}) => {
  const I18n = useI18nContext()

  if (!enabled) {
    return <>{children}</>
  }

  const tooltipText = I18n.t(
    `core.filters.emptyState.noResults.${
      hasSearch ? 'searchTooltip' : 'tooltip'
    }`,
    {
      tableName: I18n.t(`core.filters.emptyState.noResults.tableNameFallback`),
      featureName: hasSearch
        ? undefined
        : I18n.t(`core.filters.emptyState.noResults.${featureI18nKey}`),
    }
  )

  return (
    <Tooltip placement="bottom" trigger="hover" overlay={tooltipText}>
      <span>{children}</span>
    </Tooltip>
  )
}
