import React from 'react'
import type {
  Filter,
  FilterGroup,
  FilterGroupWithFilters,
  FiltersPanelProps,
} from './Filter.types'
import { StyledFiltersListWrapper } from './Filters.styles'
import { FilterGroupWrapper } from './GroupWrapper'
import { FilterListItems } from './ListItems'

type GroupedFilterName = string

/**
 * Finds and returns the matching group for a given filter.
 */
function findMatchingGroup(
  filter: Filter,
  filterGroups: FilterGroup[]
): FilterGroup | undefined {
  return filterGroups.find((group) => group.name === filter.group)
}

/**
 * Adds a filter to the corresponding group in the map.
 */
function addFilterToGroup(
  filter: Filter,
  group: FilterGroup,
  groupedFiltersMap: Map<string, FilterGroupWithFilters>
): void {
  if (!groupedFiltersMap.has(group.name)) {
    groupedFiltersMap.set(group.name, {
      name: group.name,
      displayedName: group.displayedName,
      scrollHeight: group.scrollHeight,
      filterValues: [],
    })
  }
  groupedFiltersMap.get(group.name)!.filterValues.push(filter)
}

/**
 * Groups filters by their corresponding filter group.
 * Returns a map of grouped filters and an array of filters that weren't grouped.
 */
function groupFilters(
  filters: Filter[],
  filterGroups?: FilterGroup[]
): {
  groupedFiltersMap: Map<GroupedFilterName, FilterGroupWithFilters>
  ungroupedFilters: any[]
} {
  const groupedFiltersMap = new Map<GroupedFilterName, FilterGroupWithFilters>()
  const ungroupedFilters: Filter[] = []

  if (filterGroups?.length) {
    filters.forEach((filter) => {
      if (filter.group) {
        const matchingGroup = findMatchingGroup(filter, filterGroups)
        if (matchingGroup) {
          addFilterToGroup(filter, matchingGroup, groupedFiltersMap)
          return
        }
      }
      ungroupedFilters.push(filter)
    })
  } else {
    ungroupedFilters.push(...filters)
  }

  return { groupedFiltersMap, ungroupedFilters }
}

export function FiltersList<T>(props: FiltersPanelProps<T>) {
  const { groupedFiltersMap, ungroupedFilters } = groupFilters(
    props.filters,
    props.filterGroups
  )

  return (
    <StyledFiltersListWrapper
      data-qa={`${
        props.dataQaPrefix
          ? `${props.dataQaPrefix}-possible-filters-list-container`
          : 'possible-filters-list-container'
      }`}
    >
      {Array.from(groupedFiltersMap.values()).map((group) => (
        <FilterGroupWrapper filterGroup={group} key={group.name}>
          <FilterListItems {...props} filtersInGroup={group.filterValues} />
        </FilterGroupWrapper>
      ))}
      <FilterListItems {...props} filtersInGroup={ungroupedFilters} />
    </StyledFiltersListWrapper>
  )
}
