import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box } from '../../Box'
import { FlexList } from '../../FlexList'
import { RadioButton } from '../../RadioButton'
import { useI18nContext } from '../../_hooks/I18n'
import { isValidFilter } from '../utils/isValidFilter'
import type { ServerSideNumberFilterModel } from './ServerSideNumberFilterRenderer'
import { ServerSideNumberFilterRenderer } from './ServerSideNumberFilterRenderer'

export type ServerSideMultiNumberFilterModel = ServerSideNumberFilterModel & {
  operator: 'and' | 'or'
}

type MultiNumberFilterRendererProps = {
  value?: ServerSideMultiNumberFilterModel[]
  onChange?: (models: ServerSideMultiNumberFilterModel[]) => void
}

const DEBOUNCE_TIME = 300

export function ServerSideMultiNumberFilterRenderer({
  value = [],
  onChange,
}: MultiNumberFilterRendererProps) {
  const I18n = useI18nContext()

  const [firstFilter, setFirstFilter] =
    useState<ServerSideMultiNumberFilterModel>(
      () => value[0] ?? { operator: 'and' }
    )
  const [secondFilter, setSecondFilter] =
    useState<ServerSideMultiNumberFilterModel>(() => value[1] ?? {})

  const debounceTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const prevValueRef = useRef<ServerSideMultiNumberFilterModel[]>([])

  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (prevValueRef.current.length !== 0 && value.length === 0) {
      setFirstFilter({ operator: 'and' })
      setSecondFilter({ operator: 'and' })
    }

    prevValueRef.current = value
  }, [value])

  const handleFirstFilterChange = (
    returnedFilterArray: ServerSideNumberFilterModel[]
  ) => {
    const [returnedFilter] = returnedFilterArray

    const next = returnedFilter
      ? { ...returnedFilter, operator: firstFilter.operator }
      : { operator: firstFilter.operator }

    setFirstFilter(next)

    if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current)

    debounceTimerRef.current = setTimeout(() => {
      fireOnChange(next, secondFilter)
    }, DEBOUNCE_TIME)
  }

  const handleSecondFilterChange = (
    returnedFilterArray: ServerSideNumberFilterModel[]
  ) => {
    const [returnedFilter] = returnedFilterArray
    const next = returnedFilter
      ? { ...returnedFilter, operator: firstFilter.operator }
      : { operator: firstFilter.operator }

    setSecondFilter(next)

    if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current)

    debounceTimerRef.current = setTimeout(() => {
      fireOnChange(firstFilter, next)
    }, DEBOUNCE_TIME)
  }

  const fireOnChange = useCallback(
    (
      f1: ServerSideMultiNumberFilterModel,
      f2: ServerSideMultiNumberFilterModel
    ) => {
      if (!f1.type) {
        onChange?.([])
        setFirstFilter({ operator: 'and' })
        setSecondFilter({ operator: 'and' })
        return
      }
      const f1Valid = isValidFilter(f1)
      const f2Valid = isValidFilter(f2)

      const values = [f1Valid ? f1 : null, f2Valid ? f2 : null].filter(
        (value): value is ServerSideMultiNumberFilterModel => value !== null
      )
      if (values.length) {
        onChange?.(values)
      }
    },
    [onChange]
  )

  const handleOperatorChange = (operator: 'and' | 'or') => {
    const next = { ...firstFilter, operator }
    const nextSecond = { ...secondFilter, operator }
    setFirstFilter(next)
    setSecondFilter(nextSecond)

    if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current)

    debounceTimerRef.current = setTimeout(() => {
      fireOnChange(next, nextSecond)
    }, DEBOUNCE_TIME)
  }

  const showSecondFilter = isValidFilter(firstFilter)
  const scope = 'core.filters.numberFilter'

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <ServerSideNumberFilterRenderer
        value={firstFilter.type ? [firstFilter] : []}
        onChange={handleFirstFilterChange}
      />
      {showSecondFilter && (
        <>
          <FlexList
            alignItems="center"
            justifyContent="center"
            gap="md"
            style={{ margin: '1rem 0' }}
          >
            <RadioButton
              checked={firstFilter.operator === 'and'}
              onChange={() => handleOperatorChange('and')}
            >
              {I18n.t('and', {
                defaultValue: 'and',
                scope,
              })}
            </RadioButton>

            <RadioButton
              checked={firstFilter.operator === 'or'}
              onChange={() => handleOperatorChange('or')}
            >
              {I18n.t('or', {
                defaultValue: 'or',
                scope,
              })}
            </RadioButton>
          </FlexList>

          <ServerSideNumberFilterRenderer
            value={secondFilter.type ? [secondFilter] : []}
            onChange={handleSecondFilterChange}
          />
        </>
      )}
    </Box>
  )
}
