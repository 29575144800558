import styled, { css } from 'styled-components'
import { Box } from '../Box'
import { Card } from '../Card'
import { UNSAFE_StyledFilterTokenLabel } from '../FilterToken'
import { Panel } from '../Panel'
import { Typeahead } from '../Typeahead'
import { typographyWeights } from '../Typography'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

const BORDER_WIDTH = 1
const FILTERS_GROUP_SECTION_BORDER_CSS = `${BORDER_WIDTH}px solid ${colors.gray85}`
const FILTERS_GROUP_HORIZONTAL_MARGIN = spacing.lg
const FILTERS_GROUP_VERTICAL_MARGIN = spacing.lg
const FILTERS_GROUP_EXPANDABLE_AREA_VERTICAL_MARGIN = spacing.md
const FILTERS_GROUP_ITEM_HEIGHT = 60
const ITEMS_IN_FILTERS_GROUP = 5
const FILTERS_GROUP_EXPANDABLE_AREA_HEIGHT =
  (FILTERS_GROUP_ITEM_HEIGHT + spacing.xl) * ITEMS_IN_FILTERS_GROUP +
  BORDER_WIDTH

export const StyledSearch = styled(Typeahead)<{ $isEmpty: boolean }>`
  transition: ease 500ms width;
  -webkit-transition: ease 500ms width;
`

export const StyledFilterTokenWrapper = styled.div`
  ${UNSAFE_StyledFilterTokenLabel} {
    font-weight: ${typographyWeights.semibold};
  }
`

export const StyledContextPanel = styled(Card)<{ $isVisible: boolean }>`
  max-height: 100vh;
  width: 400px;
  transition: all ease 500ms;
  flex: 0 0 auto;
  box-shadow: none;
  border-radius: 0;
  border: 1px solid ${colors.gray85};
  display: flex;
  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      border: none;
      overflow: hidden;
      padding: 0;
      margin: 0 !important;
      width: 0;
    `}
`

export const StyledContextPanelWrapper = styled(Box)`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const StyledContextPanelBody = styled(Panel.Body)`
  width: clamp(calc(400px - 20px), 400px, 100%);
`

export const StyledFiltersListWrapper = styled(Box)`
  margin: 0;
  padding: 0;
`

export const StyledFiltersList = styled.ol`
  margin: 0;
  padding: 0;
`

export const StyledPanelSection = styled(Panel.Section)<{
  groupHeight?: number
}>`
  // Filters Group container styles
  &.filters-list-group-section {
    padding-bottom: ${FILTERS_GROUP_VERTICAL_MARGIN}px;

    // Filters Group container - Expandable Header
    // The first rule is essential as it overrides a similar rule of Panel.Section
    &:first-child > div:first-child,
    & > div:first-child {
      border-bottom: ${FILTERS_GROUP_SECTION_BORDER_CSS};
      border-top: none;
      // stretch bottom divider to fit the full width of the Filters panel
      margin: 0 ${0 - FILTERS_GROUP_HORIZONTAL_MARGIN}px;
      padding: 0;

      // Arrow icon
      & > div:first-child {
        margin: 0 ${FILTERS_GROUP_HORIZONTAL_MARGIN}px;
      }
    }

    // Filters Group container - Expandable Filters list
    & div[aria-hidden='true'] {
      margin-top: ${FILTERS_GROUP_EXPANDABLE_AREA_VERTICAL_MARGIN}px;
    }
    & div[aria-hidden='false'] {
      border-top: ${FILTERS_GROUP_SECTION_BORDER_CSS};
      margin-top: ${FILTERS_GROUP_EXPANDABLE_AREA_VERTICAL_MARGIN}px;
      max-height: ${(props) =>
        !!props.groupHeight
          ? `${props.groupHeight}px`
          : `${FILTERS_GROUP_EXPANDABLE_AREA_HEIGHT}px`};
      overflow-y: auto;
      padding-top: ${FILTERS_GROUP_VERTICAL_MARGIN}px;

      // Filters List item
      ol {
        padding-bottom: ${FILTERS_GROUP_EXPANDABLE_AREA_VERTICAL_MARGIN}px;
        li {
          padding-left: ${FILTERS_GROUP_HORIZONTAL_MARGIN}px;
          padding-right: ${FILTERS_GROUP_HORIZONTAL_MARGIN}px;

          &:last-child {
            padding-bottom: ${FILTERS_GROUP_VERTICAL_MARGIN}px;
          }
        }
      }
    }
  }
`
