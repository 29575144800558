import React from 'react'
import styled, { css } from 'styled-components'
import { StyledButton } from '../../Button'
import { Popover } from '../../Popover'
import type { UNSAFE_SuperSelectConfig } from '../../SuperSelect'
import {
  UNSAFE_StyledSuperSelectArrow,
  UNSAFE_StyledSuperSelectLabel,
  UNSAFE_StyledSuperSelectTrigger,
  UNSAFE_useSuperSelectContext,
} from '../../SuperSelect'
import type { SuperSelectValue } from '../../SuperSelect/SuperSelect.types'
import { Typography, typographyWeights } from '../../Typography'
import { colors } from '../../_styles/colors'
import { spacing } from '../../_styles/spacing'

export const StyledFilterPresetPopoverContent = styled(Popover.Content)`
  padding: ${spacing.sm}px ${spacing.lg}px;
`

const getValueLabel = (
  isValueEmpty: boolean,
  value?: string,
  placeholder?: string,
  filterName?: string
) => {
  const prefixLabel = isValueEmpty ? placeholder || filterName : filterName
  const valueLabel = value ? `${prefixLabel ? ': ' : ''}${value}` : ''
  return `${prefixLabel}${valueLabel}`
}

export const getSuperSelectFilterPreset = (
  headerName?: string,
  getFilterTokenText?: (props: {
    headerName: string
    value: SuperSelectValue[]
  }) => React.FunctionComponent | string
): UNSAFE_SuperSelectConfig => {
  const filterName = headerName
  const getValueLabel_ =
    typeof getFilterTokenText === 'function'
      ? (value: SuperSelectValue) => {
          return getFilterTokenText?.({
            headerName: headerName as string,
            value: Array.isArray(value) ? value : [value],
          })
        }
      : undefined
  return {
    components: {
      Label: React.forwardRef(() => {
        const ctx = UNSAFE_useSuperSelectContext()
        const isValueEmpty = ['', null, undefined].includes(
          ctx.state.value as string
        )

        return (
          <UNSAFE_StyledSuperSelectLabel $hoverable={false}>
            {typeof getValueLabel_ === 'function'
              ? getValueLabel_(ctx.state.value)
              : getValueLabel(
                  isValueEmpty,
                  ctx.state.selectedLabel,
                  ctx.config.placeholder,
                  filterName
                )}
          </UNSAFE_StyledSuperSelectLabel>
        )
      }),
      TriggerContainer: React.forwardRef((props, ref) => {
        const ctx = UNSAFE_useSuperSelectContext()

        if (!Array.isArray(ctx.state.value)) {
          return (
            <UNSAFE_StyledSuperSelectTrigger
              $block={ctx.config.block}
              {...props}
              ref={ref}
            />
          )
        }

        return (
          <Popover
            placement="top"
            trigger={ctx.state.value.length > 1 ? 'hover' : 'none'}
            overlay={
              <StyledFilterPresetPopoverContent>
                <Typography>
                  {ctx.state.value
                    .map((v) => {
                      const option = ctx.state.options.find(
                        (option) => ctx.option.value(option) === v
                      )
                      return option ? ctx.option.label(option) : ''
                    })
                    .join(', ')}
                </Typography>
              </StyledFilterPresetPopoverContent>
            }
          >
            <UNSAFE_StyledSuperSelectTrigger
              $block={ctx.config.block}
              {...props}
              ref={ref}
            />
          </Popover>
        )
      }),
      MultiValueContainer: () => {
        const ctx = UNSAFE_useSuperSelectContext()

        if (Array.isArray(ctx.state.value)) {
          const isValueEmpty = !ctx.state.value.length
          const value =
            ctx.state.value.length > 1
              ? `(${ctx.state.value.length})`
              : ctx.state.value.length === 1
              ? `${ctx.state.selectedLabel}`
              : ''

          return (
            <UNSAFE_StyledSuperSelectLabel $hoverable={false}>
              {typeof getValueLabel_ === 'function'
                ? getValueLabel_(ctx.state.value)
                : getValueLabel(
                    isValueEmpty,
                    value,
                    ctx.config.placeholder,
                    filterName
                  )}
            </UNSAFE_StyledSuperSelectLabel>
          )
        }

        return <div>Value is not an array</div>
      },
    },
    selectionStyle: 'highlight',
  }
}

export const StyledSuperSelectWrapper = styled.div<{ $enabled: boolean }>`
  ${UNSAFE_StyledSuperSelectTrigger} {
    width: auto;
  }

  ${({ $enabled }) =>
    $enabled &&
    css`
      ${UNSAFE_StyledSuperSelectTrigger} {
        max-width: 360px;
        background: ${colors.blue96};
        border-color: ${colors.blue96};
        font-weight: ${typographyWeights.semibold};

        &:hover {
          background: ${colors.blue90};
          border-color: ${colors.blue90};
        }

        ${UNSAFE_StyledSuperSelectLabel} {
          color: ${colors.blue45};
        }

        ${StyledButton} {
          color: ${colors.blue45};

          &:hover {
            background: white;
          }
        }

        ${UNSAFE_StyledSuperSelectArrow} {
          svg {
            fill: ${colors.blue45};
          }
        }
      }
    `}
`
