import type { AcceptedTypes } from '../FilterRenderers/ServerSideNumberFilterRenderer'

export function computeLabel(
  type: Exclude<AcceptedTypes, 'any_value' | 'no_value'>,
  value?: number,
  valueTo?: number
): string {
  const from = value?.toString() ?? ''
  const to = valueTo?.toString() ?? ''

  const labelMap: Record<
    Exclude<AcceptedTypes, 'any_value' | 'no_value' | undefined>,
    (from: string, to: string) => string
  > = {
    inRange: (from, to) => `In Range from ${from} to ${to}`.trim(),
    greaterThan: (from) => `Greater than ${from}`.trim(),
    greaterThanOrEqual: (from) => `Greater than or equal to ${from}`.trim(),
    lessThan: (from) => `Less than ${from}`.trim(),
    lessThanOrEqual: (from) => `Less than or equal to ${from}`.trim(),
    isEqual: (from) => `Equal to ${from}`.trim(),
  }

  return type && labelMap[type] ? labelMap[type](from, to) : ''
}
