import styled from 'styled-components'
import { Box } from '../../Box'
import { MenuImperative } from '../../MenuImperative/MenuImperative'

const gray85 = '#d6dadc'
const spacingMd = '12px'

export const StyledLocationFilterMenu = styled(MenuImperative)`
  max-height: 400px;
`

export const StyledControlsContainer = styled(Box)`
  background-color: #ffffff;
  border-bottom: 1px solid ${gray85};
  margin-bottom: ${spacingMd};
  padding-bottom: ${spacingMd};
  padding-top: 0;
  position: sticky;
  top: -8px; // Search bar padding is 8px
  z-index: 1;
`
