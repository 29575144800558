import { getDatePlaceholder } from '@procore/globalization-toolkit'
import { isDate, isSameDay, parseISO } from 'date-fns'
import React from 'react'
import type { SegmentType } from '../../DateInput/DateInput.types'
import { FlexList } from '../../FlexList'
import { OverlayTrigger } from '../../OverlayTrigger'
import { isEventSource } from '../../_hooks/ClickOutside'
import { useDateTime } from '../../_hooks/DateTime'
import { useI18nContext } from '../../_hooks/I18n'
import type {
  DateFilter as DateFilterType,
  DateFilterCurrentSelectionType,
  DateFilterModel,
  DateFilterSelectionType,
  DateFilterSelectProps,
  DateFilterValue,
  Position,
} from '../FilterRenderers/DateFilterRenderer'
import {
  DateFilterOverlay,
  getPlacement,
  OptionalDateTimeProvider,
} from '../FilterRenderers/DateFilterRenderer'
import { getValueFromSelection, isValidDateSelection } from '../utils'
import { QuickFilterLabel } from './QuickFilterLabel'

// TODO fix Typescript
export declare type DateInputLocales = {
  [key in any]?: {
    delimiter?: string[]
    segments?: [SegmentType, SegmentType, SegmentType]
    placeholders?: {
      year: string
      month: string
      day: string
    }
  }
}

// This is from the DateInput component in core react but is not exported
// TODO: Export from core react and consume once minimum version
export const dateInputLocales = {
  'fr-CA': {
    placeholders: { day: 'jj', month: 'mm', year: 'aaaa' },
  },
  'fr-FR': {
    placeholders: { day: 'jj', month: 'mm', year: 'aaaa' },
  },
  es: {
    placeholders: { day: 'dd', month: 'mm', year: 'aaaa' },
  },
  'es-ES': {
    placeholders: { day: 'dd', month: 'mm', year: 'aaaa' },
  },
  pt: {
    placeholders: { day: 'dd', month: 'mm', year: 'aaaa' },
  },
  'is-IS': {
    placeholders: { day: 'dd', month: 'mm', year: 'áááá' },
  },
  'de-DE': {
    placeholders: { day: 'tt', month: 'MM', year: 'jjjj' },
  },
} as DateInputLocales

interface DateFilterQuickSelectProps extends DateFilterSelectProps {
  headerName: string
  disabled?: boolean
}

export const DateQuickFilterLabel = React.forwardRef<
  HTMLDivElement,
  {
    headerName: string
    onClear: () => void
    value: Date | undefined
    disabled?: boolean
  }
>(({ headerName, onClear, value, disabled }, ref) => {
  const dateTime = useDateTime()
  const i18n = useI18nContext()

  const placeholders = dateInputLocales[i18n.locale]?.placeholders || {
    day: 'dd',
    month: 'mm',
    year: 'yyyy',
  }

  // dateTime.format requires a valid date, so we can't just pass placeholder to the SelectButton and
  // instead need to render differently if there is no value
  if (value !== undefined && isDate(value)) {
    return (
      <QuickFilterLabel
        enabled={true}
        disabled={disabled}
        label={`${headerName}: ${dateTime.format(value, 'numeric-date')}`}
        onClear={onClear}
        ref={ref}
      />
    )
  }
  return (
    <QuickFilterLabel
      enabled={false}
      disabled={disabled}
      label={`${headerName}: ${getDatePlaceholder(i18n.locale, placeholders)}`}
      ref={ref}
    />
  )
})

const DateFilterSelect = React.forwardRef<
  HTMLDivElement,
  DateFilterQuickSelectProps
>(
  (
    {
      afterHide,
      selectionType = 'either',
      headerName,
      disabled,
      onChange,
      position,
      selectedValue,
      setSelectionType = () => {},
    },
    ref
  ) => {
    const dateTime = useDateTime()
    const { start, end } = selectedValue
    /** Calendar won't display selected if [selectedStart, selectedEnd]
     *  is [date, undefined] or [undefined, date]
     */
    const selectedDate = (position === 'start' ? start : end) ?? undefined
    const [displayed, setDisplayed] = React.useState(
      selectedDate || dateTime.newDate()
    )

    React.useEffect(() => {
      if (isDate(start) && !isDate(end) && position === 'end') {
        setDisplayed(start as Date)
      }
      if (!isDate(start) && isDate(end) && position === 'start') {
        setDisplayed(end as Date)
      }
    }, [position, selectedValue])

    function onClear() {
      onChange(null, position)
    }

    const clearRef = React.useRef<HTMLButtonElement>(null)
    const monthRef = React.useRef<HTMLDivElement>(null)
    const yearRef = React.useRef<HTMLDivElement>(null)

    const overlay = (
      <DateFilterOverlay
        displayDate={displayed}
        selectionType={selectionType}
        monthRef={monthRef}
        onSelect={(date) => {
          onChange(date, position)
        }}
        position={position}
        selectedValue={selectedValue}
        setDisplayDate={setDisplayed}
        setSelectionType={setSelectionType}
        yearRef={yearRef}
      />
    )

    return (
      <OverlayTrigger
        afterHide={afterHide}
        autoFocus
        beforeShow={(e) => {
          if (isEventSource(clearRef, e)) {
            return false
          }
          return true
        }}
        clickOutsideIgnoreRefs={[monthRef, yearRef]}
        overlay={overlay}
        placement={getPlacement(selectionType, position)}
        ref={ref}
        restoreFocusOnHide
        role="dialog"
      >
        <DateQuickFilterLabel
          headerName={headerName}
          onClear={onClear}
          value={selectedDate}
          disabled={disabled}
        />
      </OverlayTrigger>
    )
  }
)

export interface DateFilterProps {
  'data-qa'?: string
  headerName: string
  onChange: (val: DateFilterValue[]) => void
  selectionType?: DateFilterSelectionType
  value: string[] | Date[]
  disabled?: boolean
}

export function DateFilter({
  // TODO: talk to design about potential loading UX for this.
  // core DateSelect doesn't have it
  // loading,
  headerName,
  onChange,
  selectionType = 'either',
  value,
  disabled,
  ...props
}: DateFilterProps) {
  const [selectedValue, setSelectedValue] = React.useState<DateFilterModel>({
    type: selectionType === 'either' ? 'range' : selectionType,
  })

  React.useEffect(() => {
    const parsedValue: Date[] = value.map((date) => {
      if (isDate(date)) {
        return date as Date
      }

      return parseISO(String(date))
    })
    const type = isSameDay(parsedValue[0], parsedValue[1]) ? 'single' : 'range'
    setSelectedValue({ type, start: parsedValue[0], end: parsedValue[1] })
  }, [value])

  const dateRangeEndDateRef = React.useRef<HTMLDivElement>(null)

  function dateFilterOnChange(date: Date | null, position: Position) {
    const newValue = getValueFromSelection(selectedValue, date, position)

    // internally we want to keep track of incomplete dates
    setSelectedValue(newValue)

    // externally we only call onChange for valid ([] || [Date, Date]) values
    if (isValidDateSelection(newValue)) {
      onChange(
        newValue.start || newValue.end
          ? [
              newValue.start!,
              newValue.type === 'single' ? newValue.start! : newValue.end!,
            ]
          : []
      )
    }
  }

  const onSelectionTypeChange = React.useCallback(
    (newSelectionType: DateFilterCurrentSelectionType) => {
      setSelectedValue({ type: newSelectionType })
    },
    [setSelectedValue]
  )

  /**
   * when this is called, the setState hasn't finished yet so selectedValue
   * is still []
   *
   * option 1: introduce local state to DateFilterSelect and call afterhide with
   * selected date
   *
   * option 2: use an effect to click the second DateFilterSelect when
   * selected value changes and proper condition is met
   *
   * option 3: do something funky with refs, like storing  the selected value?
   */
  function dateRangeAfterHide() {
    if (
      selectedValue.type === 'range' &&
      isDate(selectedValue.start) &&
      !isDate(selectedValue.end)
    ) {
      dateRangeEndDateRef.current?.click()
    }
    return true
  }

  return (
    <FlexList space="xs" data-qa={props['data-qa']}>
      <DateFilterSelect
        afterHide={dateRangeAfterHide}
        headerName={headerName}
        onChange={dateFilterOnChange}
        position="start"
        selectedValue={selectedValue}
        selectionType={selectionType}
        setSelectionType={onSelectionTypeChange}
        disabled={disabled}
      />
      {selectedValue.type === 'range' && (
        <DateFilterSelect
          headerName={headerName}
          onChange={dateFilterOnChange}
          position="end"
          ref={dateRangeEndDateRef}
          selectedValue={selectedValue}
          selectionType={selectionType}
          setSelectionType={onSelectionTypeChange}
          disabled={disabled}
        />
      )}
    </FlexList>
  )
}

export interface DateQuickFilterRendererProps {
  onChange: (value: DateFilterType[]) => void
  value?: DateFilterType[]
  timeZone?: string
  headerName?: string
  selectionType?: DateFilterSelectionType
  'data-qa'?: string
  disabled?: boolean
}

export const DateQuickFilterRenderer: React.FC<
  DateQuickFilterRendererProps
> = ({
  onChange,
  value = [],
  timeZone,
  headerName,
  selectionType = 'either',
  ...props
}) => {
  /**
   * TODO: maybe make FilterComponentProps take a generic for CD so
   * we don't have to do this for proper types. Can't do typeguard
   * function check unless specfic keys (timezone or selectionType)
   * are required or defaulted
   */
  return (
    <OptionalDateTimeProvider timeZone={timeZone}>
      <DateFilter
        selectionType={selectionType}
        headerName={headerName!} // TODO: getFilterTokenText
        onChange={(selected) => {
          onChange(
            selected.map((date) => {
              return date
                ? {
                    // future extensions can go here (month, day, year keys etc)
                    date,
                  }
                : // TODO: refactor out date-specific getId stuff from useServerSideFilterStorage
                  date
            })
          )
        }}
        value={value.map((option) => (option ? option.date : option))}
        disabled={props.disabled}
        data-qa={props['data-qa']}
      />
    </OptionalDateTimeProvider>
  )
}
