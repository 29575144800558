import React from 'react'
import { UNSAFE_SuperSelect } from '../../SuperSelect'
import type { SuperSelectValue } from '../../SuperSelect/SuperSelect.types'
import {
  getSuperSelectFilterPreset,
  StyledSuperSelectWrapper,
} from './SuperSelectFilterPreset'

export interface SingleSelectQuickFilterRendererProps<
  Opt = any,
  OptValue = any
> {
  options: Opt[]
  getOptionLabel: (option: Opt) => string
  onChange: (values: SuperSelectValue) => void
  getOptionValue: (option: Opt) => OptValue
  overlayMatchesTriggerWidth?: boolean
  value?: Opt[] | Opt
  loading?: boolean
  disabled?: boolean
  onOpenChange?: (isOpen: boolean) => void
  headerName?: string
  getFilterTokenText?: ({
    headerName,
    value,
  }: {
    headerName: string
    value: SuperSelectValue[]
  }) => React.FunctionComponent | string
  'data-test-id'?: string
  'aria-label'?: string
}

export const SingleSelectQuickFilterRenderer: React.FC<
  SingleSelectQuickFilterRendererProps
> = (props) => {
  const {
    options,
    overlayMatchesTriggerWidth,
    getOptionLabel,
    value = [],
    onChange,
    loading,
    getOptionValue,
    disabled,
    onOpenChange,
    headerName,
    getFilterTokenText,
    'data-test-id': dataTestId,
    'aria-label': ariaLabel,
  } = props

  return (
    <StyledSuperSelectWrapper
      $enabled={Boolean(value?.length)}
      data-test-id={dataTestId}
    >
      <UNSAFE_SuperSelect
        overlayMatchesTriggerWidth={overlayMatchesTriggerWidth}
        loading={loading}
        value={value}
        search={false}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        multiple={false}
        options={options}
        disabled={disabled}
        onChange={onChange}
        onOpenChange={onOpenChange}
        aria-label={ariaLabel}
        {...getSuperSelectFilterPreset(headerName, getFilterTokenText)}
      />
    </StyledSuperSelectWrapper>
  )
}
