import React from 'react'
import { ToggleButton } from '../ToggleButton'
import { EmptyResultsControlTooltip } from './EmptyResultsControlTooltip'

const FilterIcon: React.FC = () => {
  return (
    <svg
      focusable={false}
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5V7H17V5H3ZM8 15H12V13H8V15ZM15 11H5V9H15V11Z"
        fill="currentColor"
      />
    </svg>
  )
}

type ButtonProps = {
  /**
   * Available filters
   */
  hasDefinedFilters: boolean
  /**
   * Selected filters
   */
  hasSelectedFilters: boolean
  /**
   * onClick handler
   */
  onClick?: () => void
  /**
   * Is button selected
   */
  isSelected?: boolean
  /**
   * Is button disabled
   */
  isDisabled?: boolean
  /**
   * data-qa attribute
   */
  ['data-qa']?: string
}

export const PanelToggleButton = ({
  hasDefinedFilters,
  hasSelectedFilters,
  isSelected,
  isDisabled = false,
  onClick,
  ['data-qa']: dataQa,
}: ButtonProps) => {
  if (!hasDefinedFilters) {
    return null
  }

  return (
    <EmptyResultsControlTooltip
      featureI18nKey="featureFilter"
      enabled={isDisabled}
    >
      <ToggleButton
        disabled={isDisabled}
        icon={<FilterIcon />}
        onClick={onClick}
        selected={isSelected}
        data-qa={dataQa}
      >
        {hasSelectedFilters ? 'More Filters' : 'Filters'}
      </ToggleButton>
    </EmptyResultsControlTooltip>
  )
}
