import React from 'react'
import { Select, type SelectProps } from '../../Select'
import { useI18nContext } from '../../_hooks/I18n'
import { defaultGetId } from '../utils/defaultGetId'
import { defaultGetLabel } from '../utils/defaultGetLabel'

export interface SingleSelectFilterProps extends Omit<SelectProps, 'onChange'> {
  getOptions?: () => Promise<boolean>
  onChange: (value: any) => void
  getId: (option: any) => string | number
  getLabel: (option: any) => string
  options: any[]
  value?: (string | number)[]
  valueId?: string | number | undefined
}

export function SingleSelectFilterRenderer({
  getOptions,
  loading,
  onChange,
  onScrollBottom,
  onSearch,
  placeholder,
  getId = defaultGetId,
  getLabel = defaultGetLabel,
  options,
  value = [],
  ...rest
}: SingleSelectFilterProps) {
  const I18n = useI18nContext()

  const handleOnClear = () => {
    onChange([])
  }

  const selectedOption = React.useMemo(() => {
    if (!value?.length) return undefined
    const [currentValue] = value

    return options.find((option) => {
      return getId(option) === currentValue
    })
  }, [value, options, getId])

  const valueId = React.useMemo(() => {
    if (!selectedOption) return undefined
    return getId(selectedOption)
  }, [selectedOption, getId])

  const label = React.useMemo(() => {
    if (!selectedOption) return undefined
    return getLabel(selectedOption)
  }, [selectedOption, getLabel])

  return (
    <Select
      {...rest}
      label={label}
      beforeShow={getOptions}
      block
      loading={loading}
      placeholder={
        placeholder ?? I18n.t('core.filters.singleSelectFilter.placeholder')
      }
      onSearch={onSearch}
      onScrollBottom={onScrollBottom}
      {...(value?.length ? { onClear: handleOnClear } : {})}
    >
      {options.map((option) => {
        const id = getId(option)
        return (
          <Select.Option
            value={id}
            key={id}
            selected={valueId === id}
            onClick={() => {
              onChange([id])
            }}
          >
            {getLabel(option)}
          </Select.Option>
        )
      })}
    </Select>
  )
}
