import React from 'react'
import { Box } from '../Box'
import { Label } from '../Semantic'
import type { FilterListItemProps } from './Filter.types'

export const FilterListItem = ({
  Component,
  columnDefinition,
  onChange,
  options,
  filterName,
  value,
  ...props
}: FilterListItemProps) => {
  return (
    <Box as="li" display="block" paddingBottom="xl" style={{ width: '100%' }}>
      {filterName && <Label>{filterName}</Label>}
      <Component
        onChange={onChange}
        options={options}
        value={value}
        {...props}
      />
    </Box>
  )
}
