import React from 'react'
import type { FilterGroup } from './Filter.types'
import { StyledPanelSection } from './Filters.styles'

export function FilterGroupWrapper(props: {
  filterGroup: FilterGroup
  children: JSX.Element
}) {
  const { filterGroup } = props

  if (filterGroup === null) {
    return props.children
  }

  return (
    <StyledPanelSection
      className="filters-list-group-section"
      expandId={`group-${filterGroup.name}`}
      groupHeight={filterGroup.scrollHeight}
      heading={filterGroup.displayedName}
      initialIsOpen={false}
    >
      {props.children}
    </StyledPanelSection>
  )
}
