import React from 'react'
import { Button } from '../Button'
import { Panel } from '../Panel'
import { useI18nContext } from '../_hooks/I18n'
import type { FilterListItem, FiltersPanelProps } from './Filter.types'
import {
  StyledContextPanel,
  StyledContextPanelBody,
  StyledContextPanelWrapper,
} from './Filters.styles'
import { FiltersList } from './List'

export const FiltersPanel = <T extends FilterListItem>(
  props: FiltersPanelProps<T>
) => {
  const I18n = useI18nContext()

  return (
    <StyledContextPanel
      $isVisible={props.isVisible}
      data-qa={`${
        props.dataQaPrefix
          ? `${props.dataQaPrefix}-filters-context-panel`
          : 'filters-context-panel'
      }`}
    >
      <StyledContextPanelWrapper display="flex-column">
        <Panel>
          <Panel.Header onClose={props.onClose}>
            <Panel.Title>{I18n.t('core.filters.filters')}</Panel.Title>
            <Button
              data-qa={`${
                props.dataQaPrefix
                  ? `${props.dataQaPrefix}-remove-all-filters`
                  : 'remove-all-filters'
              }`}
              title={I18n.t('core.filters.clearAllFilters')}
              onClick={props.onClearAllFilters}
              variant="tertiary"
            >
              {I18n.t('core.filters.clearAllFilters')}
            </Button>
          </Panel.Header>
          <StyledContextPanelBody>
            <Panel.Section>
              <FiltersList {...props} />
            </Panel.Section>
          </StyledContextPanelBody>
        </Panel>
      </StyledContextPanelWrapper>
    </StyledContextPanel>
  )
}
