import { prop, startsWith, uniqBy } from 'ramda'
import React from 'react'
import { Card } from '../../Card'
import { Flex } from '../../Flex'
import type { UNSAFE_MenuRef } from '../../MenuImperative'
import {
  UNSAFE_Menu,
  UNSAFE_menuItemsWrapperAttribute,
  UNSAFE_useMenuImperativeControlNavigation,
} from '../../MenuImperative'
import type { Selection } from '../../MenuImperative/MenuImperative.types'
import type { MultiSelectOptionItem } from '../../MultiSelect'
import { OverlayTrigger } from '../../OverlayTrigger'
import { Spinner } from '../../Spinner'
import { useI18nContext } from '../../_hooks/I18n'
import {
  StyledControlsContainer,
  StyledLocationFilterMenu,
} from '../FilterRenderers/LocationFilterRenderer.styles'
import type {
  Id,
  LocationFilteRendererProps,
  LocationFilterOverlayProps,
  LocationOption,
  SelectionType,
} from '../FilterRenderers/LocationFilterRenderer.type'
import { defaultGetId } from '../utils/defaultGetId'
import { defaultGetLabel } from '../utils/defaultGetLabel'
import { logger } from '../utils/logger'
import { QuickFilterLabel } from './QuickFilterLabel'

const LocationQuickFilterOverlay = React.forwardRef<
  HTMLDivElement,
  LocationFilterOverlayProps
>(
  (
    {
      getId,
      getLabel,
      loading,
      onSelect: _onSelect,
      onSelectAll,
      onSelectSublocations,
      enableSublocations,
      selectState,
      options,
      disabledOptions,
      onSearch,
      value,
    },
    ref
  ) => {
    const [items, setItems] = React.useState<LocationOption[]>(options)
    const [searching, setSearching] = React.useState<boolean>(false)
    const menuRef = React.useRef<UNSAFE_MenuRef | null>(null)
    const { menuProps, menuNavigationTriggerProps } =
      UNSAFE_useMenuImperativeControlNavigation(menuRef)
    const I18n = useI18nContext()

    React.useEffect(() => {
      menuRef.current?.highlightSuggested()
    }, [])

    if (value && !Array.isArray(value)) {
      logger.warn(
        'a value was set that is not compatible with this LocationQuickFilterRenderer'
      )
      return null
    }

    const selectAllOption = {
      label: I18n.t('core.filters.locationFilter.selectAll'),
      id: 'select_all',
    }

    const includeSublocationOption = {
      label: I18n.t('core.filters.locationFilter.includeSublocations'),
      id: 'include_sublocations',
    }

    const onSelect = (selection: Selection) => {
      if (selection.item.id === selectAllOption.id) {
        return onSelectAll()
      }

      if (selection.item.id === includeSublocationOption.id) {
        _onSelect(selection)
        return onSelectSublocations()
      }

      return _onSelect(selection)
    }

    function defaultOnSearch(e: React.ChangeEvent<HTMLInputElement>) {
      if (e.target.value === '') {
        setSearching(false)
        setItems(options)
      } else {
        setSearching(true)
        setItems(
          options.filter((item) =>
            getLabel(item).toLowerCase().includes(e.target.value.toLowerCase())
          )
        )
      }
    }

    const _onSearch = onSearch || defaultOnSearch

    return (
      <Card ref={ref}>
        <StyledLocationFilterMenu
          {...menuProps}
          multiple
          role="listbox"
          ref={menuRef}
          onSelect={onSelect}
        >
          <UNSAFE_Menu.Search
            {...menuNavigationTriggerProps}
            onChange={_onSearch}
          />
          <UNSAFE_Menu.Options>
            {!searching && (
              <StyledControlsContainer {...UNSAFE_menuItemsWrapperAttribute}>
                <UNSAFE_Menu.CheckboxItem
                  key={getId(includeSublocationOption)}
                  item={includeSublocationOption}
                  selected={enableSublocations}
                >
                  {getLabel(includeSublocationOption)}
                </UNSAFE_Menu.CheckboxItem>
                <UNSAFE_Menu.CheckboxItem
                  key={getId(selectAllOption)}
                  item={selectAllOption}
                  selected={selectState === 'all'}
                  indeterminate={selectState === 'partial'}
                >
                  {getLabel(selectAllOption)}
                </UNSAFE_Menu.CheckboxItem>
              </StyledControlsContainer>
            )}
            {!loading &&
              items.map((item, i) => {
                return (
                  <UNSAFE_Menu.CheckboxItem
                    key={getId(item)}
                    item={item}
                    selected={
                      value.map(({ id }) => id).includes(getId(item)) ||
                      disabledOptions.map(({ id }) => id).includes(getId(item))
                    }
                    suggested={i === 0}
                    disabled={disabledOptions
                      .map(({ id }) => id)
                      .includes(getId(item))}
                  >
                    {getLabel(item)}
                  </UNSAFE_Menu.CheckboxItem>
                )
              })}
          </UNSAFE_Menu.Options>
          {loading && (
            <UNSAFE_Menu.Footer>
              <Flex justifyContent="center">{<Spinner size="sm" />}</Flex>
            </UNSAFE_Menu.Footer>
          )}
        </StyledLocationFilterMenu>
      </Card>
    )
  }
)

export const LocationQuickFilterRenderer: React.FC<
  LocationFilteRendererProps
> = (props) => {
  const {
    options,
    value = [],
    onChange,
    loading,
    disabled,
    getOptions,
    getId = defaultGetId,
    getLabel = defaultGetLabel,
    onSearch,
  } = props
  const I18n = useI18nContext()
  const determineSelectedState = (selection: LocationOption[]) => {
    if (options.length === selection.length) {
      return 'all'
    }

    if (
      selection.length &&
      (options.length > selection.length || options.length < selection.length)
    ) {
      return 'partial'
    }

    if (selection.length === 0) {
      return 'none'
    }
    return 'none'
  }

  const ids = React.useMemo(() => value.map(({ id }) => id), [value])
  const [selectedValueIds, setSelectedValueIds] = React.useState<Id[]>(ids)
  const [selectState, setSelectState] = React.useState<SelectionType>(
    determineSelectedState(value)
  )
  const [disabledValues, setDisabledValues] = React.useState<LocationOption[]>(
    []
  )

  const [enableSublocations, setEnableSublocations] =
    React.useState<boolean>(false)
  const onSelectSublocations = React.useCallback(() => {
    if (enableSublocations) {
      setDisabledValues([])
    } else {
      const newDisabledValues: LocationOption[] = []
      value.forEach((location) => {
        newDisabledValues.push(...findSublocations(location))
      })
      // for disabling only options were not selected
      const uniqDisabledValues = uniqBy(prop('id'), newDisabledValues).filter(
        ({ id }) => !selectedValueIds.includes(id)
      )
      setDisabledValues(uniqDisabledValues)
    }
    setEnableSublocations(!enableSublocations)
  }, [enableSublocations, value, selectedValueIds, options])

  const onSelectAll = React.useCallback(() => {
    if (selectState === 'none' || selectState === 'partial') {
      setSelectState('all')
      setSelectedValueIds(options.map(({ id }) => id))
      onChange(options)
    }

    if (selectState === 'all') {
      setSelectState('none')
      setSelectedValueIds([])
      onChange([])
    }
  }, [options, selectState, selectedValueIds])

  const onSelect = (selection: Selection) => {
    if (selection.item.id === 'select_all') {
      return
    }
    if (selection.item.id === 'include_sublocations') {
      return onChange(
        value.map((item) => ({
          ...item,
          sublocations: enableSublocations ? [] : findSublocations(item),
        }))
      )
    }

    let newSelections = []

    if (selectedValueIds.includes(getId(selection.item))) {
      newSelections = removeSelections(selection.item, value)
    } else {
      newSelections = [...value, selection.item]
      const sublocationsToDisable = handleSelectSublocations(selection.item)

      //setting only sublocations that is not selected previously (for nested sublocations)
      const newDisabledValues = [
        ...disabledValues,
        ...sublocationsToDisable.filter(
          ({ id }) => !selectedValueIds.includes(id)
        ),
      ]
      setDisabledValues(newDisabledValues)
    }

    setSelectState(determineSelectedState(newSelections))
    setSelectedValueIds(newSelections.map(({ id }) => id))
    //add sublocation param that will be empty for include_sublocations: false and fullfilled in other case
    onChange(
      newSelections.map((item) => ({
        ...item,
        sublocations: handleSelectSublocations(item),
      }))
    )
  }

  const normalizeLabel = (label: string) =>
    label
      .split('>')
      .map((part) => part.trim())
      .join(' > ')

  const findSublocations = (selected: LocationOption) => {
    return options.filter((option) => {
      return (
        startsWith(
          normalizeLabel(getLabel(selected)).toLowerCase(),
          normalizeLabel(getLabel(option)).toLowerCase()
        ) && option.id !== selected.id
      )
    })
  }
  const handleSelectSublocations = (selected: LocationOption) => {
    if (enableSublocations) {
      const filteredOptions = findSublocations(selected)

      return filteredOptions
    } else {
      return []
    }
  }

  const removeSelections = React.useCallback(
    function (
      selection: MultiSelectOptionItem,
      selected: MultiSelectOptionItem[]
    ) {
      const sublocations = handleSelectSublocations(selection)
      const disabledValueIds = disabledValues.map(({ id }) => id)
      //find sublocations that were disabled
      const sublocationsToRemoveFromDisabledIds = sublocations
        .filter(({ id }) => disabledValueIds.includes(id))
        .map(({ id }) => id)
      //filter disabled locations
      setDisabledValues(
        disabledValues.filter(
          ({ id }) => !sublocationsToRemoveFromDisabledIds.includes(id)
        )
      )
      return selected.filter((item) => getId(item) !== getId(selection))
    },
    [enableSublocations, disabledValues]
  )

  React.useEffect(() => {
    setSelectState(determineSelectedState(value))
  }, [value])

  const overlay = (
    <LocationQuickFilterOverlay
      enableSublocations={enableSublocations}
      onSelectAll={onSelectAll}
      onSelectSublocations={onSelectSublocations}
      selectState={selectState}
      value={value}
      options={options}
      disabledOptions={disabledValues}
      getId={getId}
      getLabel={getLabel}
      loading={loading}
      onSelect={onSelect}
      onSearch={onSearch}
    />
  )

  const triggerLabel = value.length
    ? value.length === 1
      ? `: ${value[0].label}`
      : `: (${value.length})`
    : ''

  return (
    <OverlayTrigger
      overlay={overlay}
      beforeShow={getOptions}
      placement={'bottom-left'}
    >
      {value.length ? (
        <QuickFilterLabel
          enabled={true}
          disabled={disabled}
          label={`${I18n.t(
            'core.filters.locationFilter.locations'
          )}${triggerLabel}`}
          onClear={() => {
            setEnableSublocations(false)
            setDisabledValues([])
            onChange([])
          }}
          block
        />
      ) : (
        <QuickFilterLabel
          enabled={false}
          disabled={disabled}
          label={`${I18n.t(
            'core.filters.locationFilter.locations'
          )}${triggerLabel}`}
          block
        />
      )}
    </OverlayTrigger>
  )
}
