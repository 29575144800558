import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Flex } from '../../Flex'
import { FlexList } from '../../FlexList'
import { Input } from '../../Input'
import { Select } from '../../Select'
import { Typography } from '../../Typography'
import { useI18nContext } from '../../_hooks/I18n'
import { computeLabel } from '../utils/computeLabel'
import { getNewFilterType } from '../utils/getNewNumberFilterType'

export type AcceptedTypes =
  | 'inRange'
  | 'greaterThan'
  | 'greaterThanOrEqual'
  | 'lessThan'
  | 'lessThanOrEqual'
  | 'no_value'
  | 'isEqual'

export type ServerSideNumberFilterModel = {
  label?: string
  type?: AcceptedTypes
  value?: number
  valueTo?: number
}

export type NumberFilterValue = ServerSideNumberFilterModel[]

type NumberFilterRendererProps = {
  value?: NumberFilterValue
  onChange?: (models: NumberFilterValue) => void
}

const DEBOUNCE_TIME = 300

export function ServerSideNumberFilterRenderer({
  value = [],
  onChange,
}: NumberFilterRendererProps) {
  const I18n = useI18nContext()

  const [localFilter, setLocalFilter] = useState<ServerSideNumberFilterModel>(
    () => {
      return {}
    }
  )
  const prevValueRef = useRef<NumberFilterValue>([])
  const debounceTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (prevValueRef.current.length !== 0 && value.length === 0) {
      setLocalFilter({})
    }

    prevValueRef.current = value
  }, [value])

  const transformToFilterArray = useCallback(
    (filter: ServerSideNumberFilterModel) => {
      if (!filter.type) {
        return []
      }

      return [filter]
    },
    []
  )

  const updateFilter = useCallback(
    (next: ServerSideNumberFilterModel) => {
      setLocalFilter(next)
      onChange?.(transformToFilterArray(next))
    },
    [onChange, transformToFilterArray]
  )

  const scope = 'core.filters.numberFilter'

  const filterOptions = useMemo(
    () => [
      {
        id: 'inRange',
        label: I18n.t('options.is_between', {
          defaultValue: 'Is Between',
          scope,
        }),
      },
      {
        id: 'greaterThan',
        label: I18n.t('options.greater_than', {
          defaultValue: 'Is Greater Than',
          scope,
        }),
      },
      {
        id: 'greaterThanOrEqual',
        label: I18n.t('options.greater_than_equal_to', {
          defaultValue: 'Is Greater Than or Equal To',
          scope,
        }),
      },
      {
        id: 'isEqual',
        label: I18n.t('options.isEqual', {
          defaultValue: 'Is Equal To',
          scope,
        }),
      },
      {
        id: 'lessThan',
        label: I18n.t('options.less_than', {
          defaultValue: 'Is Less Than',
          scope,
        }),
      },
      {
        id: 'lessThanOrEqual',
        label: I18n.t('options.less_than_equal_to', {
          defaultValue: 'Is Less Than or Equal To',
          scope,
        }),
      },
      {
        id: 'no_value',
        label: I18n.t('options.no_value', {
          defaultValue: 'No Value',
          scope,
        }),
      },
    ],
    [I18n]
  )

  const showRangeInputs = localFilter.type === 'inRange'
  const showSingleInput =
    !!localFilter.type && localFilter.type !== 'no_value' && !showRangeInputs

  const handleFilterTypeChange = (selectedItem: { id: AcceptedTypes }) => {
    const newType = selectedItem.id
    const newFilterType = getNewFilterType(newType)

    if (newType === 'no_value') {
      updateFilter(newFilterType)
    }

    setLocalFilter(newFilterType)
  }

  const handleDebouncedInputChange = useCallback(
    (next: ServerSideNumberFilterModel) => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }

      debounceTimerRef.current = setTimeout(() => {
        if (next.type === 'inRange') {
          if (next.value !== undefined && next.valueTo !== undefined) {
            updateFilter(next)
          }
        } else {
          updateFilter(next)
        }
      }, DEBOUNCE_TIME)
    },
    [updateFilter]
  )

  const handleLowerLimitChange: React.ComponentProps<
    typeof Input
  >['onChange'] = (event) => {
    const raw = event.target.value
    const numericValue = raw === '' ? undefined : Number(raw)

    const currentType = localFilter.type || 'greaterThan'

    if (currentType !== 'no_value') {
      const next: ServerSideNumberFilterModel = {
        ...localFilter,
        type: currentType,
        value: numericValue,
        label:
          currentType === 'inRange'
            ? computeLabel('inRange', numericValue, localFilter.valueTo)
            : computeLabel(currentType, numericValue),
      }

      setLocalFilter(next)
      handleDebouncedInputChange(next)
    }
  }

  const handleUpperLimitChange: React.ComponentProps<
    typeof Input
  >['onChange'] = (event) => {
    const raw = event.target.value
    const numericValue = raw === '' ? undefined : Number(raw)
    const currentType = localFilter.type || 'inRange'

    if (currentType !== 'no_value') {
      const next: ServerSideNumberFilterModel = {
        ...localFilter,
        type: currentType,
        valueTo: numericValue,
        label: computeLabel(currentType, localFilter.value, numericValue),
      }

      setLocalFilter(next)
      handleDebouncedInputChange(next)
    }
  }

  const handleClear = () => {
    setLocalFilter({})
    onChange?.([])
  }

  const selectedOptionLabel = useMemo(() => {
    if (!localFilter.type) return undefined
    return filterOptions.find((option) => option.id === localFilter.type)?.label
  }, [localFilter, filterOptions])

  return (
    <Flex direction="column" gap="8px">
      <Select
        block
        label={selectedOptionLabel}
        placeholder={I18n.t('labels.placeholder', {
          defaultValue: 'Select an item',
          scope,
        })}
        onSelect={({ item }) =>
          handleFilterTypeChange(item as { id: AcceptedTypes })
        }
        onClear={handleClear}
      >
        {filterOptions.map((option) => (
          <Select.Option
            key={option.id}
            value={option}
            selected={option.id === localFilter.type}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>

      {showSingleInput && (
        <Input
          type="number"
          placeholder={I18n.t('labels.input_placeholder', {
            defaultValue: 'Enter Value',
            scope,
          })}
          value={localFilter.value ?? ''}
          onChange={handleLowerLimitChange}
        />
      )}

      {showRangeInputs && (
        <FlexList alignItems="center" style={{ width: '100%' }} size="xs">
          <Input
            type="number"
            placeholder={I18n.t('labels.input_placeholder', {
              defaultValue: 'Enter Value',
              scope,
            })}
            value={localFilter.value ?? ''}
            onChange={handleLowerLimitChange}
          />

          <Typography style={{ padding: '0 8px' }}>
            {I18n.t('labels.and', { defaultValue: 'and', scope })}
          </Typography>

          <Input
            type="number"
            placeholder={I18n.t('labels.input_placeholder', {
              defaultValue: 'Enter Value',
              scope,
            })}
            value={localFilter.valueTo ?? ''}
            onChange={handleUpperLimitChange}
          />
        </FlexList>
      )}
    </Flex>
  )
}
