import { isDate, isSameDay, parseISO } from 'date-fns'
import React from 'react'
import { Box } from '../../Box'
import { Calendar } from '../../Calendar'
import { Card } from '../../Card'
import { DateInput, UNSAFE_isValidYearRange } from '../../DateInput'
import { Flex } from '../../Flex'
import { FlexList } from '../../FlexList'
import type { OverlayTriggerProps } from '../../OverlayTrigger'
import {
  OverlayTrigger,
  UNSAFE_useOverlayTriggerContext,
} from '../../OverlayTrigger'
import { SegmentedController } from '../../SegmentedController'
import { isEventSource } from '../../_hooks/ClickOutside'
import { DateTimeProvider, useDateTime } from '../../_hooks/DateTime'
import { useI18nContext } from '../../_hooks/I18n'
import { getValueFromSelection, isValidDateSelection } from '../utils'

export type Position = 'start' | 'end'

export type DateFilterSelectionType = 'single' | 'range' | 'either'
export type DateFilterValue = Date

export interface DateFilter {
  date: Date
}

export type DateFilterModel = {
  type: DateFilterCurrentSelectionType
  start?: Date | null
  end?: Date | null
}
export type DateFilterCurrentSelectionType = Omit<
  DateFilterSelectionType,
  'either'
>

export interface DateRangeHTMLDivElement extends HTMLDivElement {
  attributes: HTMLDivElement['attributes'] & { open?: string }
}

export interface DateFilterSelectProps {
  afterHide?: OverlayTriggerProps['afterHide']
  currentSelectionType?: DateFilterCurrentSelectionType
  loading?: boolean
  onChange: (date: Date | null, position: Position) => void
  position: Position
  selectionType?: DateFilterSelectionType
  setSelectionType?: (
    currentSelectionType: DateFilterCurrentSelectionType
  ) => void
  selectedValue: DateFilterModel
  dataQaPrefix?: string
}

export interface DateFilterOverlayProps {
  dateInputSegmentRef?: React.RefObject<HTMLDivElement>
  displayDate: Date
  monthRef: React.RefObject<HTMLDivElement>
  onSelect: (date: Date) => void
  yearRef: React.RefObject<HTMLDivElement>
  position: Position
  selectionType: DateFilterSelectionType
  selectedValue: DateFilterModel
  setDisplayDate: (date: Date) => void
  setSelectionType: (
    currentSelectionType: DateFilterCurrentSelectionType
  ) => void
  dataQaPrefix?: string
}

export interface DateFilterComponentProps {
  onChange: (value: DateFilter[] | undefined) => void
  timeZone: string | undefined
  selectionType: DateFilterSelectionType
  value: Array<DateFilter>
  'data-qa'?: string
  dataQaPrefix?: string
}

export function getPlacement(
  currentSelectionType: DateFilterCurrentSelectionType,
  position: Position
) {
  if (currentSelectionType === 'single') {
    return 'bottom-left'
  }
  if (position === 'start') {
    return 'bottom-left'
  }
  return 'bottom-right'
}

export const DateFilterTokenText = ({
  headerName,
  value,
}: {
  headerName: string
  value: string[]
}) => {
  const dateTime = useDateTime()
  const [start, end] = value
  const parsedStart = parseISO(start)
  const parsedEnd = parseISO(end)

  // single filter
  if (isSameDay(parsedStart, parsedEnd)) {
    return (
      <>{`${headerName}: ${dateTime.format(parsedStart, 'numeric-date')}`}</>
    )
  }
  return (
    <>
      {`${headerName}: ${dateTime.format(
        parsedStart,
        'numeric-date'
      )} - ${dateTime.format(parsedEnd, 'numeric-date')}`}
    </>
  )
}

export const DateFilterOverlay = React.forwardRef<
  HTMLDivElement,
  DateFilterOverlayProps
>(
  // eslint-disable-next-line complexity
  (
    {
      dateInputSegmentRef,
      displayDate,
      monthRef,
      onSelect,
      position,
      selectionType,
      selectedValue,
      setDisplayDate,
      setSelectionType,
      yearRef,
      ...props
    },
    ref
  ) => {
    const I18n = useI18nContext()
    const { start, end } = selectedValue
    let calendarStart = start
    let calendarEnd = end

    /**
     * Calendar won't display selected if [selectedStart, selectedEnd]
     * is [date, undefined] or [undefined, date]
     * []
     * [date, null] left calendar start/end should be value[0] value[0]
     * [null, date] right calendar start/end should be value[1] value[1]
     * [date, date] calendar start/end should be value[0], value[1]
     */

    if (selectedValue.type === 'range') {
      if (position === 'start' && isDate(start) && !isDate(end)) {
        calendarEnd = start
      }
      if (position === 'end' && isDate(end) && !isDate(start)) {
        calendarStart = end
      }
    }

    const { hide } = UNSAFE_useOverlayTriggerContext()
    function calendarOnSelect(day: Date) {
      dateInputSegmentRef?.current && dateInputSegmentRef.current.focus()
      setDisplayDate(day)
      onSelect(day)

      hide({})
    }
    // date-filter-selection-control
    return (
      <Card
        data-qa={
          props.dataQaPrefix
            ? `${props.dataQaPrefix}-date-filter-calendar`
            : 'date-filter-calendar'
        }
        ref={ref}
        {...props}
      >
        <Flex direction="column">
          {selectionType === 'either' && (
            <Box padding="md" style={{ width: '100%' }}>
              <SegmentedController
                block
                data-qa={
                  props.dataQaPrefix
                    ? `${props.dataQaPrefix}-date-filter-selection-control`
                    : 'date-filter-selection-control'
                }
              >
                <SegmentedController.Segment
                  active={selectedValue.type === 'single'}
                  onClick={() => setSelectionType('single')}
                >
                  {I18n.t('core.filters.dateFilter.single')}
                </SegmentedController.Segment>
                <SegmentedController.Segment
                  active={selectedValue.type === 'range'}
                  onClick={() => setSelectionType('range')}
                >
                  {I18n.t('core.filters.dateFilter.range')}
                </SegmentedController.Segment>
              </SegmentedController>
            </Box>
          )}
          <Calendar
            displayDate={displayDate}
            monthRef={monthRef}
            onNavigate={setDisplayDate}
            onSelect={calendarOnSelect}
            selectedEnd={calendarEnd ?? undefined}
            selectedStart={calendarStart ?? undefined}
            yearRef={yearRef}
          />
        </Flex>
      </Card>
    )
  }
)

const DateFilterSelect = React.forwardRef<
  HTMLDivElement,
  DateFilterSelectProps
>(
  (
    {
      afterHide,
      onChange,
      position,
      selectedValue,
      selectionType = 'either',
      setSelectionType = () => {},
      dataQaPrefix,
    },
    ref
  ) => {
    const dateTime = useDateTime()
    const { start, end } = selectedValue
    /** Calendar won't display selected if [selectedStart, selectedEnd]
     *  is [date, undefined] or [undefined, date]
     */
    const selectedDate = (position === 'start' ? start : end) ?? undefined
    const [displayed, setDisplayed] = React.useState(
      // @ts-ignore
      selectedDate || dateTime.newDate()
    )

    React.useEffect(() => {
      if (isDate(start) && !isDate(end) && position === 'end') {
        setDisplayed(start as Date)
      }
      if (!isDate(start) && isDate(end) && position === 'start') {
        setDisplayed(end as Date)
      }
    }, [position, selectedValue])

    // DateInput refs
    const segmentRefs = {
      segmentThree: React.useRef<HTMLDivElement>(null),
    }
    const clearRef = React.useRef<HTMLButtonElement>(null)
    const monthRef = React.useRef<HTMLDivElement>(null)
    const yearRef = React.useRef<HTMLDivElement>(null)

    const overlay = (
      <DateFilterOverlay
        dateInputSegmentRef={segmentRefs.segmentThree}
        displayDate={displayed}
        monthRef={monthRef}
        onSelect={(date) => {
          onChange(date, position)
        }}
        position={position}
        selectedValue={selectedValue}
        selectionType={selectionType}
        setDisplayDate={setDisplayed}
        setSelectionType={setSelectionType}
        yearRef={yearRef}
      />
    )

    return (
      <OverlayTrigger
        afterHide={afterHide}
        beforeShow={(e) => {
          if (isEventSource(clearRef, e)) {
            return false
          }
          return true
        }}
        clickOutsideIgnoreRefs={[monthRef, yearRef]}
        overlay={overlay}
        placement={getPlacement(selectedValue.type, position)}
        ref={ref}
      >
        <DateInput
          data-qa={
            dataQaPrefix
              ? `${dataQaPrefix}-date-filter-input-${position}`
              : `date-filter-input-${position}`
          }
          segmentRefs={segmentRefs}
          onChangeSegment={(type, value) => {
            if (value === -1) {
              return
            }

            if (type === 'month') {
              // @ts-ignore
              setDisplayed(dateTime.newDate(displayed.setMonth(value - 1)))
            } else if (type === 'year') {
              // isValidYearRange prevents calendar changing the display date
              if (UNSAFE_isValidYearRange(value)) {
                // @ts-ignore
                setDisplayed(dateTime.newDate(displayed.setFullYear(value)))
              }
            }
          }}
          onChange={(_date: Date | null) => {
            /** TODO: Handle clearing a single segment. We currently
             *  don't know which segment was cleared or which have values.
             */

            onChange(_date, position)
          }}
          onClear={() => {
            // already fires with onChange, so only need to update displayed
            // @ts-ignore
            setDisplayed(dateTime.newDate())
          }}
          clearRef={clearRef}
          value={selectedDate}
          ref={ref}
        />
      </OverlayTrigger>
    )
  }
)

export function DateFilter({
  // TODO: talk to design about potential loading UX for this.
  // core DateSelect doesn't have it
  // loading,
  onChange,
  selectionType = 'either',
  value,
  ...props
}: {
  'data-qa'?: string
  onChange: (val: DateFilterValue[]) => void
  selectionType?: DateFilterSelectionType
  value: string[] | Date[]
  dataQaPrefix?: string
}) {
  const [selectedValue, setSelectedValue] = React.useState<DateFilterModel>({
    type: selectionType === 'either' ? 'range' : selectionType,
  })

  React.useEffect(() => {
    const parsedValue: Date[] = value.map((date) => {
      if (isDate(date)) {
        return date as Date
      }

      return parseISO(String(date))
    })
    const type = isSameDay(parsedValue[0], parsedValue[1]) ? 'single' : 'range'
    setSelectedValue({ type, start: parsedValue[0], end: parsedValue[1] })
  }, [value])

  const dateRangeEndDateRef = React.useRef<DateRangeHTMLDivElement>(null)

  function dateFilterOnChange(date: Date | null, position: Position) {
    const newValue = getValueFromSelection(selectedValue, date, position)

    // internally we want to keep track of incomplete dates
    setSelectedValue(newValue)

    // externally we only call onChange for valid ([] || [Date, Date]) values
    if (isValidDateSelection(newValue)) {
      onChange(
        newValue.start || newValue.end
          ? [
              newValue.start!,
              newValue.type === 'single' ? newValue.start! : newValue.end!,
            ]
          : []
      )
    }
  }

  const onSelectionTypeChange = React.useCallback(
    (newSelectionType: DateFilterCurrentSelectionType) => {
      setSelectedValue({ type: newSelectionType })
    },
    [setSelectedValue]
  )

  /**
   * when this is called, the setState hasn't finished yet so selectedValue
   * is still []
   *
   * option 1: introduce local state to DateFilterSelect and call afterhide with
   * selected date
   *
   * option 2: use an effect to click the second DateFilterSelect when
   * selected value changes and proper condition is met
   *
   * option 3: do something funky with refs, like storing  the selected value?
   */
  function dateRangeAfterHide() {
    if (
      selectedValue.type === 'range' &&
      isDate(selectedValue.start) &&
      !isDate(selectedValue.end) &&
      !dateRangeEndDateRef.current?.attributes?.open
    ) {
      dateRangeEndDateRef.current?.click()
    }
    return true
  }

  return (
    <FlexList space="xs" data-qa={props['data-qa']}>
      <DateFilterSelect
        afterHide={dateRangeAfterHide}
        onChange={dateFilterOnChange}
        position="start"
        selectedValue={selectedValue}
        selectionType={selectionType}
        setSelectionType={onSelectionTypeChange}
        dataQaPrefix={props.dataQaPrefix}
      />
      {selectedValue.type !== 'single' && (
        <DateFilterSelect
          onChange={dateFilterOnChange}
          position="end"
          ref={dateRangeEndDateRef}
          selectedValue={selectedValue}
          selectionType={selectionType}
          setSelectionType={onSelectionTypeChange}
          dataQaPrefix={props.dataQaPrefix}
        />
      )}
    </FlexList>
  )
}

/** Determines if a new DateTimeProvider context should be added,
 * or do nothing so children can inherit an above context.
 * @returns DateTimeProvider | React.Fragment */
export const OptionalDateTimeProvider: React.FC<
  React.ComponentProps<typeof DateTimeProvider>
> = ({ timeZone, children }) => {
  return timeZone ? (
    <DateTimeProvider timeZone={timeZone}>{children}</DateTimeProvider>
  ) : (
    <>{children}</>
  )
}

export function DateSelectFilterRenderer({
  // loading, unused in current core DateSelect
  onChange,
  value = [],
  timeZone,
  selectionType = 'either',
  ...props
}: DateFilterComponentProps) {
  /**
   * TODO: maybe make FilterComponentProps take a generic for CD so
   * we don't have to do this for proper types. Can't do typeguard
   * function check unless specfic keys (timezone or selectionType)
   * are required or defaulted
   */
  return (
    <OptionalDateTimeProvider timeZone={timeZone}>
      <DateFilter
        {...props}
        onChange={(selected) => {
          onChange(
            selected.map((date) => {
              return date
                ? {
                    // future extensions can go here (month, day, year keys etc)
                    date,
                  }
                : // TODO: refactor out date-specific getId stuff from useServerSideFilterStorage
                  date
            })
          )
        }}
        selectionType={selectionType}
        value={value.map((option) => (option ? option.date : option))}
      />
    </OptionalDateTimeProvider>
  )
}
