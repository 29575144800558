import React from 'react'
import styled, { css } from 'styled-components'
import { StyledButton } from '../../Button'
import type { SelectButtonProps } from '../../Select'
import {
  SelectButton,
  StyledSelectArrow,
  StyledSelectButton,
  StyledSelectButtonLabel,
} from '../../Select'
import { typographyWeights } from '../../Typography'
import { colors } from '../../_styles/colors'

export interface QuickFilterLabelProps extends SelectButtonProps {
  /** If true, the Quick Filter will show its selected (blue) UI */
  enabled: boolean
}

/** This label component should be used as the trigger for quick filters that *do not* use SuperSelect.
 *  Only applies styles, does not do label formatting
 */
export const QuickFilterLabel = React.forwardRef<
  HTMLDivElement,
  QuickFilterLabelProps
>(({ enabled, ...props }, ref) => {
  return (
    <StyledQuickFilterLabel $enabled={enabled}>
      <SelectButton ref={ref} {...props}></SelectButton>
    </StyledQuickFilterLabel>
  )
})

export const StyledQuickFilterLabel = styled.div<{ $enabled: boolean }>`
  ${StyledSelectButton} {
    width: auto;
    max-width: 380px;
    ${({ $enabled }) =>
      $enabled &&
      css`
        background: ${colors.blue96};
        border-color: ${colors.blue96};
        font-weight: ${typographyWeights.semibold};
        &:hover {
          background: ${colors.blue90};
          border-color: ${colors.blue90};
        }
        ${StyledSelectButtonLabel} {
          color: ${colors.blue45};
        }
        ${StyledButton} {
          color: ${colors.blue45};
          &:hover {
            background: white;
          }
        }
        ${StyledSelectArrow} {
          svg {
            fill: ${colors.blue45};
          }
        }
      `}
  }
`
