import React from 'react'
import { UNSAFE_FilterToken as Token } from '../FilterToken'
import { FlexList } from '../FlexList'
import { EmptyResultsControlTooltip } from './EmptyResultsControlTooltip'
import { StyledFilterTokenWrapper } from './Filters.styles'

export type Filter = {
  field: string
  type?: 'set' | 'number'
  options?: any[]
  filterValues?: any[]
  quickFilterRenderer?: React.FC<any>
  quickFilterProps?: any
}

type ColumnDefinition = {
  quickFilterRenderer?: any
}

interface QuickFiltersPropsBase {
  /** Callback for when any filter's value changes. */
  onChange?: (field: string, value: any[]) => void

  /** Array of available filters. */
  filters: Filter[]

  /** Array of current filter tokens. */
  tokens: Filter[]

  /** Callback invoked when a filter or token is removed. */
  onRemove: (field: string) => void

  /** Indicates if filters are disabled. */
  isDisabled?: boolean

  /** Whether filter overlays match the trigger width. */
  overlayMatchesTriggerWidth?: boolean

  /** Data attribute prefix for testing. */
  dataQaPrefix?: string
}

/**
 * Variation when a column definition is NOT provided.
 * - getColumnDefinition must be undefined.
 * - getTokenLabel must be omitted.
 */
interface QuickFiltersPropsNoDef<T extends ColumnDefinition>
  extends QuickFiltersPropsBase {
  getColumnDefinition?: undefined
  getTokenLabel?: never
}

/**
 * Variation when a column definition IS provided.
 * - getColumnDefinition is required.
 * - getTokenLabel is required.
 */
interface QuickFiltersPropsWithDef<T extends ColumnDefinition>
  extends QuickFiltersPropsBase {
  getColumnDefinition: (field: string) => T | undefined
  getTokenLabel: (
    columnDefinition: T,
    filter: Filter
  ) => string | React.ReactNode
}

/**
 * We define a union type that enforces:
 *  - If getColumnDefinition is absent, getTokenLabel is absent.
 *  - If getColumnDefinition is present, getTokenLabel is required.
 */
export type QuickFiltersProps<T extends ColumnDefinition> =
  | QuickFiltersPropsNoDef<T>
  | QuickFiltersPropsWithDef<T>

/**
 * QuickFilters Component
 *
 * This component displays two sets of elements:
 * 1) Filters (which may be either string-based or Filter objects), each rendered by
 *    a column-specific quickFilterRenderer or a fallback quickFilterRenderer.
 * 2) Tokens (a summary of chosen filters), each labeled by getTokenLabel.
 *
 * If both filters and tokens are empty, nothing is rendered.
 *
 * Type constraints:
 *  - If you provide getColumnDefinition, you MUST provide getTokenLabel.
 *  - If you do not provide getColumnDefinition, you CANNOT provide getTokenLabel.
 */
export const QuickFilters = <T extends ColumnDefinition>({
  onChange,
  filters,
  tokens,
  getColumnDefinition,
  isDisabled,
  onRemove,
  overlayMatchesTriggerWidth,
  getTokenLabel,
  dataQaPrefix,
}: QuickFiltersProps<T>) => {
  const isEmpty = filters.length === 0 && tokens.length === 0

  return isEmpty ? null : (
    <FlexList
      padding="xs none"
      size="none"
      alignItems="center"
      wrap="wrap"
      gap="sm"
      data-qa={`${
        dataQaPrefix ? `${dataQaPrefix}-quick-filters` : 'quick-filters'
      }`}
    >
      {filters.map((filter) => {
        const filterField = filter.field
        const colDef = getColumnDefinition?.(filterField)
        const QuickFilter =
          colDef?.quickFilterRenderer ?? filter.quickFilterRenderer

        if (!QuickFilter) {
          return null
        }

        return (
          <EmptyResultsControlTooltip
            featureI18nKey="featureQuickFilter"
            enabled={Boolean(isDisabled)}
            key={filterField}
          >
            <QuickFilter
              filter={filter}
              columnDefinition={colDef}
              disabled={Boolean(isDisabled)}
              {...(onChange && {
                onChange: (value: any[]) => onChange?.(filterField, value),
              })}
              onRemove={() => onRemove(filterField)}
              overlayMatchesTriggerWidth={overlayMatchesTriggerWidth}
              {...(!colDef && {
                value: filter.filterValues,
                options: filter.options,
                ...filter.quickFilterProps,
              })}
            />
          </EmptyResultsControlTooltip>
        )
      })}
      {tokens.map((token) => {
        const filterField = token.field
        const colDef = getColumnDefinition?.(filterField)
        const label =
          getTokenLabel?.(colDef!, token) ??
          token?.quickFilterProps?.getTokenLabel?.(token)

        if (!label || token.quickFilterRenderer) return null
        return (
          <StyledFilterTokenWrapper key={filterField}>
            <Token>
              <Token.Label>{label}</Token.Label>
              <Token.Remove
                onClick={() => onRemove(filterField)}
                data-qa={`${
                  dataQaPrefix
                    ? `${dataQaPrefix}-quick-filter-remove-token`
                    : 'quick-filter-remove-token'
                }`}
              />
            </Token>
          </StyledFilterTokenWrapper>
        )
      })}
    </FlexList>
  )
}
